import { User } from 'components/page/user'
import { MainHeader } from 'layout/Header/MainHeader'
import { Footer } from 'layout/Footer'
import styled from 'styled-components'

const UserPage = () => {
  return (
    <StyledUser className='footer_margin'>
      <MainHeader className='userPageHeader' />
      <User />
      <Footer />
    </StyledUser>
  )
}

export default UserPage

const StyledUser = styled.div`
  .userPageHeader {
    background: ${({ theme }) => theme.background};
  }
`
