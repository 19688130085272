import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useUser } from 'components/functions/recoil/hooks'
import { useNavigate } from 'react-router-dom'
import { changeFavorite } from 'components/functions/firebase/hooks'
import { Modal, Button } from 'components/commons'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

/**
 * 使い方
 * @example
 * <FavButton
 favorite={favorite}
 postId={postId}
 className={'className'}
 fontSize={2}
 iconSize={2}
 circleSize=52,
 isCount=false,
/>
 */

export const FavButton = ({
  favorite,
  postId,
  className,
  fontSize,
  iconSize,
  circleSize = 35,
  isCount = true,
  circleClass,
}) => {
  const navigate = useNavigate()
  const { user } = useUser()
  const [favCount, setFavCount] = useState(favorite.length)
  const [isLiked, setIsLiked] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setIsLiked(favorite.includes(user.userId))
  }, [user])

  const favClick = () => {
    if (user.userId === '') {
      setShowModal(true)
    } else {
      changeFavorite(postId, user.userId)
      setFavCount(isLiked ? favCount - 1 : favCount + 1)
      setIsLiked(!isLiked)
    }
  }

  return (
    <StyledFavButton
      fontSize={fontSize}
      iconSize={iconSize}
      circleSize={circleSize}
    >
      <div className={`${className} fav`}>
        {isCount && <div className='fav_count'>{favCount}</div>}
        <div className={`${circleClass} center fav_circle`}>
          {isLiked ? (
            <FavoriteIcon onClick={() => favClick()} className={'done_fav'} />
          ) : (
            <FavoriteBorderIcon
              onClick={() => favClick()}
              className={'not_fav'}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={showModal}
        closed={() => setShowModal(false)}
        className='modal'
      >
        <div className='login-prompt'>
          <h1 className='login-prompt-text'>You must join</h1>
          <Button onClick={() => navigate('/login')}>ログイン</Button>
          <p>or</p>
          <Button onClick={() => navigate('/signup')}>アカウント作成</Button>
        </div>
      </Modal>
    </StyledFavButton>
  )
}

const StyledFavButton = styled.div`
  .fav {
    display: flex;
    justify-content: left;
    align-items: center;

    &_count {
      font-size: ${({ fontSize }) => fontSize}rem;
      margin-right: 10px;
    }

    &_circle {
      width: ${({ circleSize }) => circleSize}px;
      height: ${({ circleSize }) => circleSize}px;
      border-radius: 100%;
      background-color: #fcfcfc;
    }
  }

  .done_fav {
    font-size: ${({ iconSize }) => iconSize}rem;
    color: #ff087a;
    filter: drop-shadow(0px 0px 4px #ff087a4d);
    cursor: pointer;
    stroke: 1px;
  }

  .not_fav {
    font-size: ${({ iconSize }) => iconSize}rem;
    cursor: pointer;
    color: #00000029;
    stroke: #fff;
  }
  .modal {
    width: 300px;
    height: 350px;
    border-radius: 20px;
    position: relative;
    background-color: rgba(235, 245, 252, 0.7);
    .login-prompt {
      text-align: center;
      display: flex;
      flex-flow: column;
      row-gap: 24px;
      h1 {
        font-weight: bold;
        font-size: 18px;
      }
      p {
        font-size: 18px;
      }
    }
  }
`
