import { Withdrawal } from 'components/page/withdrawal'
import { MainHeader } from 'layout/Header/MainHeader'
import styled from 'styled-components'

const WithdrawalPage = () => {
  return (
    <StyledWithdrawal>
      <MainHeader />
      <Withdrawal />
    </StyledWithdrawal>
  )
}

export default WithdrawalPage

const StyledWithdrawal = styled.div``
