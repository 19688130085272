import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * const [value, setValue] = useState()
 *
 * <InputForm
 *   label='sample'
 *   onChange={(e) => setValue(e.target.value)}
 *   className='sample_form'
 * />
 * ```
 */

export const InputForm = ({
  className,
  label,
  type,
  value,
  defaultValue,
  onChange,
  onClick,
  onKeyPress,
  autoComplete,
  required,
  readonly,
}) => {
  return (
    <StyledInputForm className={`${className}`}>
      <label htmlFor={label} className='label'>
        {label}
      </label>
      <input
        id={label}
        type={type}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        className='form'
        autoComplete={autoComplete}
        required={required}
        readOnly={readonly}
      />
    </StyledInputForm>
  )
}

const StyledInputForm = styled.div`
  .form {
    font-size: 1.6rem;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background: ${({ theme }) => theme.background};
    box-shadow: inset 2px 2px 10px #00000029;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[readonly] {
    background-color: #e6e6e6;
    color: #999999;
  }
`
