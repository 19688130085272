import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * const [value, setValue] = useState()
 *
 * <Textarea
 *   label='sample'
 *   onChange={(e) => setValue(e.target.value)}
 *   className='sample_form'
 * />
 * ```
 */

export const Textarea = ({ className, label, ...props }) => {
  return (
    <StyledTextarea className={`${className}`}>
      <label className='label'>{label}</label>
      <textarea id={label} type='text' className='form' {...props} />
    </StyledTextarea>
  )
}

const StyledTextarea = styled.div`
  .form {
    font-size: 1.6rem;
    width: 100%;
    padding: 5px 10px;
    border-radius: 8px;
    background: ${({ theme }) => theme.background};
    box-shadow: inset 2px 2px 10px #00000029;
  }
`
