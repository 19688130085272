/**
 * 市区町村以下を除いた住所を返す関数です。(村は判定せず郡とする)
 * @param city 都道府県が含まれない住所
 * @return 切り出した住所を返す。(例外はそのままcityを返す)
 */

export const checkMunicipalities = (city) => {
  const exception =
    /田村郡|北村山郡|西村山郡|東村山郡|余市郡|高市郡|大村市|村上市|羽村市|武蔵村山市|東村山市|田村市|村山市|大町市|十日町市|町田市|廿日市市|四日市市|野々市市|市原市|市川市|市|区|町|郡|村/
  try {
    const divideCity = city.substring(
      0,
      city.match(exception)[0].length + city.match(exception).index
    )
    return divideCity
  } catch (error) {
    return city
  }
}
