import styled from 'styled-components'
import { Footer } from 'layout/Footer'
import { MainHeader } from 'layout/Header/MainHeader'

const PolicyPage = () => {
  return (
    <StyledPolicyPage className='footer_margin'>
      <MainHeader className='ruleHeader' />
      <div className='wrap'>
        <h1>プライバシーポリシー</h1>
        <h2> 1.&emsp;個人情報の利用について</h2>
        <p>
          1-1.&emsp;当サービスでは、アカウント作成・ログイン・パスワード変更時にメールアドレス等の個人情報を使用します。取得した個人情報は、認証機能で利用させていただくものであり、これらの目的以外では利用いたしません。
        </p>
        <p>
          1-2.&emsp;当サービスでは、お問い合わせの際にメールアドレス等の個人情報を入力いただく場合があります。取得した個人情報は、必要な連絡のみに利用させていただくものであり、これらの目的以外では利用いたしません。
        </p>

        <h2>2022年7月1日制定</h2>
      </div>
      <Footer />
    </StyledPolicyPage>
  )
}

export default PolicyPage

const StyledPolicyPage = styled.div`
  .ruleHeader {
    background: ${({ theme }) => theme.background};
  }
  .wrap {
    padding: 0 20px;
  }
  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-left: 10px;
  }
  h2 {
    font-size: 1.6rem;
    margin-top: 20px;
    font-weight: bold;
  }
  p {
    margin-top: 5px;
    font-size: 1.2rem;
  }
`
