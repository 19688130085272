import { TimeLineHeader } from 'layout/Header/TimeLineHeader'
import { TimeLine } from 'components/page/home'
import styled from 'styled-components'
import { Footer } from 'layout/Footer'

const HomePage = () => {
  return (
    <StyledHome className='footer_margin'>
      <TimeLineHeader />
      <TimeLine />
      <Footer />
    </StyledHome>
  )
}

export default HomePage

const StyledHome = styled.div``
