import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Home, Search, CirclePlus, Bell, Person } from 'akar-icons'

export const Footer = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <StyledFooter>
      <div className='footer drop_shadow sb'>
        <button
          onClick={() => navigate('/')}
          disabled={pathname === '/'}
          className={`footer_btn ${
            pathname === '/' ? 'footer_btn-active' : ''
          }`}
        >
          <Home className='footer_btn-icon' />
        </button>

        <button
          onClick={() => navigate('/search')}
          disabled={pathname.includes('search')}
          className={`footer_btn ${
            pathname.includes('search') ? 'footer_btn-active' : ''
          }`}
        >
          <Search className='footer_btn-icon' />
        </button>

        <button
          onClick={() => navigate('/post/new')}
          disabled={pathname.includes('post')}
          className={`footer_btn ${
            pathname.includes('post') ? 'footer_btn-active' : ''
          }`}
        >
          <CirclePlus className='footer_btn-plusIcon' />
        </button>

        <button
          onClick={() => navigate('/comingsoon')}
          disabled={pathname.includes('comingsoon')}
          className={`footer_btn ${
            pathname.includes('comingsoon') ? 'footer_btn-active' : ''
          }`}
        >
          <Bell className='footer_btn-icon' />
        </button>

        <button
          onClick={() => navigate('/user/profile')}
          disabled={
            pathname.includes('user') ||
            pathname.includes('login') ||
            pathname.includes('signup')
          }
          className={`footer_btn ${
            pathname.includes('user') ||
            pathname.includes('login') ||
            pathname.includes('signup')
              ? 'footer_btn-active'
              : ''
          }`}
        >
          <Person className='footer_btn-icon' />
        </button>
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2; // swiper表示より上に来るようにする
  ${({ theme }) => theme.media.tab`
      max-width: 375px; //position: fixed;を当てるとGlobalでbodyに当てたmax-widthが効かなくなる
    `}
  ${({ theme }) => theme.media.pc`
      max-width: 375px; //position: fixed;を当てるとGlobalでbodyに当てたmax-widthが効かなくなる
    `}
  .footer {
    width: 100%;
    height: 65px;
    padding: 0px 40px;
    border-radius: 40px;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.footer.footer_black};
  }
  .footer_btn {
    cursor: pointer;
    &::after {
      content: '';
      width: 5px;
      height: 5px;
      margin: 0 auto;
      display: block;
      border-radius: 100%;
    }
    &-icon {
      width: 30px;
      height: 30px;
      stroke-width: 1.5px;
    }
    &-plusIcon {
      width: 35px;
      height: 35px;
      stroke-width: 1.5px;
    }
    &-active {
      color: ${({ theme }) => theme.footer.footer_blue};
      &::after {
        background-color: ${({ theme }) => theme.footer.footer_blue};
      }
    }
  }
`
