import { storage } from 'components/functions/firebase/config'
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage'
import { resizeFile } from 'components/functions/hooks/resizeFile'


/* 画像をstorageに保存する */
export const saveImage = async (path, files) => {
  let images = []
  // filesは配列、中身はFileオブジェクト（新規画像） or URL（既存画像）。新規画像だけstorageに追加処理する
  if (files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      if (files[i].name) {
        const filePath = `${path}/${files[i].name}`
        const storageRef = ref(storage, filePath)
        const image = await resizeFile(files[i])
        await uploadBytes(storageRef, image)
        const url = await getDownloadURL(storageRef)
        images.push(url)
      } else {
        images.push(files[i])
      }
    }
  }
  return images
}
