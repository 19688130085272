/* 画像データをURLに変換する関数(引数は配列) */
export const createFileURL = (files) => {
  // MEMO: ドモルガンの法則で、!process.browser || !files いこーる !(process.browser && files)
  if (!(process.browser && files)) {
    return
  }

  return files.map((file) => {
    // fileがURL(文字列)ならそのまま返す
    if (typeof file === 'string') {
      return file
    }
    // fileがオブジェクト(File, Blob, MediaSource)ならURLに変換する
    if (typeof file === 'object') {
      return window.URL.createObjectURL(file)
    }
  })
}
