import { db } from 'components/functions/firebase/config'
import {
  collection,
  doc,
  query,
  where,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
  arrayRemove,
  getDocsFromServer,
  writeBatch,
} from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'
import { storage } from 'components/functions/firebase/config'
import { ref, deleteObject } from 'firebase/storage'
import { getPostDetail } from 'components/functions/firebase/hooks/getPostDetail'
import { compareArrays } from 'components/functions/hooks/compareArrays'
import { saveImage } from 'components/functions/firebase/hooks/saveImage'
import { addReport } from 'components/functions/firebase/hooks'

const postsCollectionRef = collection(db, 'posts')
const newPostsDocRef = () => doc(postsCollectionRef)

const hotelsCollectionRef = collection(db, 'hotels')
const newHotelsDocRef = () => doc(hotelsCollectionRef)

const fetchSnapshot = async (hotelName) => {
  const q = query(hotelsCollectionRef, where('hotelName', '==', hotelName))
  return getDocsFromServer(q)
}

const prefectureDivision = (prefecture) => {
  const hokkaidou = ['北海道']
  const touhoku = ['青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県']
  const kantou = [
    '東京都',
    '神奈川県',
    '千葉県',
    '埼玉県',
    '群馬県',
    '栃木県',
    '茨城県',
  ]
  const tyuubu = [
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '愛知県',
    '静岡県',
  ]
  const kinki = [
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '奈良県',
    '和歌山県',
    '兵庫県',
  ]
  const tyuugoku = ['鳥取県', '島根県', '岡山県', '広島県', '山口県']
  const shikoku = ['香川県', '徳島県', '高知県', '愛媛県']
  const kyushu = [
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ]
  if (hokkaidou.includes(prefecture)) return '北海道'
  if (touhoku.includes(prefecture)) return '東北'
  if (kantou.includes(prefecture)) return '関東'
  if (tyuubu.includes(prefecture)) return '中部'
  if (kinki.includes(prefecture)) return '近畿'
  if (tyuugoku.includes(prefecture)) return '中国'
  if (shikoku.includes(prefecture)) return '四国'
  if (kyushu.includes(prefecture)) return '九州'
  return ''
}

const timeout = (ms) => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      const err = new FirebaseError('timeout', '接続がタイムアウトしました')
      reject(err)
    }, ms)
  })
}

/* ユーザー新規投稿 */
export const post = async (files, postData, user, hotelInfo, hotelDetail) => {
  const postsDocRef = newPostsDocRef()
  const hotelsDocRef = newHotelsDocRef()
  const postId = postsDocRef.id
  const storagePath = `posts/${user.userId}/${postId}`
  try {
    const snapshot = await fetchSnapshot(hotelInfo.hotelName)
    const hotelId = snapshot.empty
      ? hotelsDocRef.id
      : snapshot.docs.map((doc) => doc.id)[0]
    const postImg = await saveImage(storagePath, files)
    const batch = writeBatch(db)
    const batchFunc = async () => {
      // postsにドキュメントを作成
      batch.set(postsDocRef, {
        atDate: serverTimestamp(),
        comment: postData.comment,
        disabled: false,
        favorite: [],
        hotelId,
        hotelName: hotelInfo.hotelName ?? '',
        postId,
        postImg,
        price: Number(postData.price),
        roomName: postData.roomName,
        userId: user.userId,
        value: postData.value,
        prefecture: hotelInfo.address1 ?? '',
        city: hotelInfo.address2 ?? '',
        area: hotelDetail.areaName ?? '',
        prefectureDivision: prefectureDivision(hotelInfo.address1),
      })

      // hotelsのドキュメントを作成 or 更新
      if (snapshot.empty) {
        batch.set(hotelsDocRef, {
          atDate: serverTimestamp(),
          hotelId,
          hotelImg: [],
          hotelName: hotelInfo.hotelName ?? '',
          postId: [postId],
          url: '',
          jalanUrl: '',
          jtbUrl: '',
          rakutenUrl: hotelInfo.hotelInformationUrl ?? '',
          station: hotelInfo.nearestStation ?? '',
          hotelKanaName: hotelInfo.hotelKanaName ?? '',
          rakutenHotelNo: hotelInfo.hotelNo ?? '',
          prefecture: hotelInfo.address1 ?? '',
          city: hotelInfo.address2 ?? '',
          area: hotelDetail.areaName ?? '',
          prefectureDivision: prefectureDivision(hotelInfo.address1),
        })
      } else {
        /* すでに登録されている場合はpostIdを追加 */
        batch.update(doc(hotelsCollectionRef, hotelId), {
          postId: arrayUnion(postId),
        })
      }

      // 登録済みのユーザならusersのドキュメントを更新
      const usersDocRef = doc(db, 'users', user.userId)
      const usersDocSnap = await getDoc(usersDocRef)
      if (usersDocSnap.exists()) {
        batch.update(usersDocRef, { posts: arrayUnion(postId) })
      }
    }
    // タイムアウト関数で指定した時間までに一連の投稿処理が完了した時のみFirestoreに反映させている
    await Promise.race([batchFunc(), timeout(60 * 1000)]).then(() =>
      batch.commit()
    )
  } catch (error) {
    switch (error.code) {
      case 'timeout':
        throw new Error(
          '通信がタイムアウトしました。通信環境を確認してもう一度お試しください'
        )
      case 'unavailable':
        throw new Error(
          'インターネットの接続がありません. 通信環境を確認してもう一度お試しください'
        )
      case 'cancelled':
        throw new Error('操作がキャンセルされました')
      case 'resource-exhausted':
        await addReport({
          type: 'FirebaseError',
          code: 'resource-exhausted',
          message:
            '一部のリソースが使い果たされているか、ユーザーごとの割り当てが不足しているか、ファイルシステム全体のスペースが不足している可能性があります。',
        })
        throw new Error('エラーが起きました。')
      case 'storage/retry-limit-exceeded':
        throw new Error(
          '画像のアップロード処理がタイムアウトしました。もう一度アップロードしてみてください。'
        )
      case 'storage/canceled':
        throw new Error('操作がキャンセルされました')
      case 'storage/quota-exceeded':
        await addReport({
          atDate: serverTimestamp(),
          type: 'FirebaseError',
          code: 'storage/quota-exceeded',
          message:
            'Cloud Storage バケットの割り当てを超えました。ノーコスト枠でご利用中の場合は、有料プランにアップグレードしてください。有料プランをお使いの場合は、Firebase サポートまでお問い合わせください。',
        })
        throw new Error('エラーが起きました。')
    }
    throw new Error('予期せぬエラーが起こりました。')
  }
}

/* ユーザー投稿編集 */
// TODO: この関数でもerror のキャッチが必要
export const updatePost = async (postData, files, hotelInfo, hotelDetail) => {
  try {
    const hotelsDocRef = newHotelsDocRef()
    const postDataBeforeChange = await getPostDetail(postData.postId)
    const postImg = await (async () => {
      const storagePath = `posts/${postDataBeforeChange.userId}/${postData.postId}`
      /* 画像が変わった場合は差分のみ更新する */
      if (compareArrays(postDataBeforeChange.postImg, files)) {
        return postDataBeforeChange.postImg
      } else {
        // 保存済みのファイル名と更新データのファイル名を比較して差分（ユーザーが削除したファイル名）を抽出
        const deletedFileUrls = postDataBeforeChange.postImg.filter(
          (img) => !files.includes(img)
        )
        // 差分があればStorageから削除する
        if (deletedFileUrls.length > 0) {
          deletedFileUrls.forEach(async (file) => {
            await deleteObject(ref(storage, file))
          })
        }
        return await saveImage(storagePath, files)
      }
    })()
    const hotelId = await (async () => {
      /* 投稿のホテル名が変わっていたらhotelIdとpostIdを付け直す */
      if (postDataBeforeChange.hotelName === hotelInfo.hotelName) {
        return postDataBeforeChange.hotelId
      } else {
        await updateDoc(doc(db, 'hotels', postDataBeforeChange.hotelId), {
          postId: arrayRemove(postData.postId),
        })
        const snapshot = await fetchSnapshot(hotelInfo.hotelName)
        if (snapshot.empty) {
          await setDoc(hotelsDocRef, {
            atDate: serverTimestamp(),
            hotelId: hotelsDocRef.id,
            hotelImg: [],
            hotelName: hotelInfo.hotelName ?? '',
            postId: [],
            url: '',
            jalanUrl: '',
            jtbUrl: '',
            rakutenUrl: hotelInfo.hotelInformationUrl ?? '',
            station: hotelInfo.nearestStation ?? '',
            hotelKanaName: hotelInfo.hotelKanaName ?? '',
            rakutenHotelNo: hotelInfo.hotelNo ?? '',
            prefecture: hotelInfo.address1 ?? '',
            city: hotelInfo.address2 ?? '',
            area: hotelDetail.areaName ?? '',
            prefectureDivision: prefectureDivision(hotelInfo.address1),
          })
        }
        const hotelIdAfterChange = snapshot.empty
          ? hotelsDocRef.id
          : snapshot.docs.map((doc) => doc.id)[0]
        await updateDoc(doc(db, 'hotels', hotelIdAfterChange), {
          postId: arrayUnion(postData.postId),
        })
        return hotelIdAfterChange
      }
    })()
    await updateDoc(doc(db, 'posts', postDataBeforeChange.postId), {
      atDate: serverTimestamp(),
      comment: postData.comment,
      disabled: false,
      favorite: postDataBeforeChange.favorite,
      hotelId,
      hotelName: hotelInfo.hotelName ?? '',
      postId: postDataBeforeChange.postId,
      postImg,
      price: Number(postData.price),
      roomName: postData.roomName,
      userId: postDataBeforeChange.userId,
      value: postData.value,
      prefecture: hotelInfo.address1 ?? '',
      city: hotelInfo.address2 ?? '',
      area: hotelDetail.areaName ?? '',
      prefectureDivision: prefectureDivision(hotelInfo.address1),
    })
  } catch (error) {
    throw Error(error)
  }
}

/* ホテル新規投稿 */
export const hotelPost = async (
  files,
  postHotelData,
  hotelInfo,
  hotelDetail
) => {
  try {
    /* ホテルの重複チェック */
    const q = query(
      hotelsCollectionRef,
      where('hotelName', '==', hotelInfo.hotelName)
    )
    const snapshot = await getDocs(q)
    if (snapshot.empty) {
      /* 新規追加 */
      const hotelsDocRef = newHotelsDocRef()
      const storagePath = `hotels/${hotelsDocRef.id}`
      /* 画像をstorageに保存する */
      const hotelImg = await saveImage(storagePath, files)

      await setDoc(hotelsDocRef, {
        atDate: serverTimestamp(),
        hotelId: hotelsDocRef.id,
        hotelImg,
        hotelName: hotelInfo.hotelName ?? postHotelData.hotelName,
        postId: [],
        url: postHotelData.url,
        jalanUrl: postHotelData.jalanUrl,
        jtbUrl: postHotelData.jtbUrl,
        rakutenUrl:
          postHotelData.rakutenUrl === ''
            ? hotelInfo.hotelInformationUrl ?? ''
            : postHotelData.rakutenUrl,
        station: hotelInfo.nearestStation ?? '',
        hotelKanaName: hotelInfo.hotelKanaName ?? '',
        rakutenHotelNo: hotelInfo.hotelNo ?? '',
        prefecture: hotelInfo.address1 ?? '',
        city: hotelInfo.address2 ?? '',
        area: hotelDetail.areaName ?? '',
        prefectureDivision: prefectureDivision(hotelInfo.address1),
      })
      return hotelsDocRef.id
    } else {
      const hotelImg = await (async () => {
        const storagePath = `hotels/${postHotelData.hotelId}}`
        /* 画像が変わった場合は差分のみ更新する */
        if (compareArrays(postHotelData.hotelImg, files)) {
          return postHotelData.hotelImg
        } else {
          // 保存済みのファイル名と更新データのファイル名を比較して差分（ユーザーが削除したファイル名）を抽出
          const deletedFileUrls = postHotelData.hotelImg.filter(
            (img) => !files.includes(img)
          )
          // 差分があればStorageから削除する
          if (deletedFileUrls.length > 0) {
            deletedFileUrls.forEach(async (file) => {
              await deleteObject(ref(storage, file))
            })
          }
          return await saveImage(storagePath, files)
        }
      })()
      /* すでに登録されている場合はpostIdを追加 */
      const hotelId = snapshot.docs.map((doc) => doc.id)[0]
      await updateDoc(doc(hotelsCollectionRef, hotelId), {
        atDate: serverTimestamp(),
        hotelImg: hotelImg,
        hotelName: hotelInfo.hotelName ?? postHotelData.hotelName,
        url: postHotelData.url,
        jalanUrl: postHotelData.jalanUrl,
        jtbUrl: postHotelData.jtbUrl,
        rakutenUrl: postHotelData.rakutenUrl,
        station: hotelInfo.nearestStation ?? '',
        hotelKanaName: hotelInfo.hotelKanaName ?? '',
        rakutenHotelNo: hotelInfo.hotelNo ?? '',
        prefecture: hotelInfo.address1 ?? '',
        city: hotelInfo.address2 ?? '',
        area: hotelDetail.areaName ?? '',
        prefectureDivision: prefectureDivision(hotelInfo.address1),
      })
      return hotelId
    }
  } catch (error) {
    throw Error(error)
  }
}
