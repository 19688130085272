import { useFetchFavoritePosts } from 'components/functions/firebase/hooks/useFetchFavoritePosts'
import styled from 'styled-components'

export const FavPosts = ({ favorites, onClickImg }) => {
  const { favoritePosts } = useFetchFavoritePosts(favorites)

  return (
    <StyledPostsContainer>
      {favoritePosts && favoritePosts.length > 0 && (
        <div className='PostsContainer'>
          {favoritePosts.map((favoritePost, key) => {
            return (
              <img
                src={favoritePost.postImg}
                onClick={() => onClickImg(favoritePost.postId)}
                key={key}
                className='photo'
              />
            )
          })}
        </div>
      )}
    </StyledPostsContainer>
  )
}

const StyledPostsContainer = styled.div`
  .PostsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px auto;

    &::after {
      content: '';
      display: block;
      width: 32%;
      height: 0;
    }
  }

  .photo {
    width: 98px;
    height: 98px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 5px;
    margin: 0 1% 20px 1%;
  }
`
