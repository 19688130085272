import { Glassmorphism } from 'components/commons/Glassmorphism'
import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * const [isOpen, setIsOpen] = useState(false)
 *
 * <Modal isOpen={isOpen} closed={()=>setIsOpen(false)}>
 *   <p>Modal</p>
 * </Modal>
 * ```
 */

export const Modal = ({ children, className, isOpen, closed }) => {
  if (isOpen) {
    return (
      <StyledModal onClick={closed} className='w-full h-full'>
        <div className='modal' onClick={(e) => e.stopPropagation()}>
          <Glassmorphism className={`${className} fade_in`}>
            {children}
          </Glassmorphism>
        </div>
      </StyledModal>
    )
  } else return null
}

const StyledModal = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  /* Modalの周りの画面を暗くするときはこれを使う */
  /* background-color: rgba(0, 0, 0, 0.1); */

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .fade_in {
    animation: fade 0.25s;
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
