import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * const [btn, setBtn] = useState(false)
 *
 * <Button
 *   onClick={() => setBtn(!btn)}
 *   disabled={btn}
 *   className='btn'
 * >
 *  Button
 * </Button>
 * ```
 */

export const Button = ({ children, className, onClick, disabled }) => {
  return (
    <StyledButton>
      <button
        className={`neumo_btn center ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </StyledButton>
  )
}

const StyledButton = styled.div`
  .neumo_btn {
    width: 100%;
    height: 100%;
    padding: 5px;
    border-radius: 20px;
    background: ${({ theme }) => theme.background};
    box-shadow: 2px 2px 2px #bec3c9, -2px -2px 2px #fff;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    &:active {
      color: ${({ theme }) => theme.main};
      box-shadow: inset 2px 2px 2px #bec3c9, inset -2px -2px 2px #fff;
      transition: 0.5s;
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.gray};
      box-shadow: inset 2px 2px 2px #bec3c9, inset -2px -2px 2px #fff;
      transition: 0.5s;
    }
  }
`
