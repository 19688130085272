import { db } from 'components/functions/firebase/config'
import {
  getDocs,
  query,
  orderBy,
  limit,
  collection,
  where,
  startAfter,
} from 'firebase/firestore'
import { getUserData } from 'components/functions/firebase/hooks'

export const addPostData = async (postsData, limitSet) => {
  try {
    if (postsData.length === 0) {
      return []
    }
    const lastPostData = postsData.slice(-1)[0]
    const q = query(
      collection(db, 'posts'),
      where('disabled', '==', false),
      orderBy('atDate', 'desc'),
      startAfter(lastPostData.atDate),
      limit(limitSet)
    )
    const querySnapshot = await getDocs(q)
    return await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const post = doc.data()
        const user = await getUserData(post.userId)
        const postData = {
          userIcon: user.userIcon,
          userName: user.userName,
          ...post,
        }
        return postData
      })
    )
  } catch (e) {
    throw Error(e)
  }
}
