/**
 * @param { string } mail - バリデーションにかけたいメールアドレス
 * @return { boolean } - バリデーションに通ったか否か
 * @example
 * ```jsx
 * const isValid = checkMail('sample@example.com')
 * ```
 */

// メアド正規表現
const eMailRegex =
  /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

export const checkMail = (mail) => eMailRegex.test(mail)
