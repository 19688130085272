import { Provider } from 'Provider'
import { Routes, Route } from 'react-router-dom'
import { PrivateRoute } from 'components/model/privateRoute'

import HomePage from 'pages/Home'
import CreatePostPage from 'pages/CreatePost'
import PostDetailPage from 'pages/PostDetail'
import EditPostPage from 'pages/EditPost'
import SearchPage from 'pages/Search'
import LoginPage from 'pages/Login'
import ForgotPasswordPage from 'pages/ForgotPassword'
import SignupPage from 'pages/Signup'
import ProfilePage from 'pages/Profile'
import UserPage from 'pages/User'
import DevelopPage from 'pages/Develop'
import CreateHotelPage from 'pages/CreateHotel'
import ActionPage from 'pages/Action'
import ContactPage from 'pages/Contact'
import ComingSoon from 'pages/ComingSoon'
import NotFoundPage from 'pages/NotFound'
import RulePage from 'pages/Rule'
import PolicyPage from 'pages/Policy'
import WithdrawalPage from 'pages/Withdrawal'
import { DevMenu } from 'components/model/develop/DevMenu'

const App = () => {
  return (
    <Provider>
      {/* 開発用メニュー */}
      {process.env.NODE_ENV === 'development' ? <DevMenu /> : <></>}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route
          path='/post/new'
          element={<PrivateRoute Component={CreatePostPage} />}
        />
        <Route path='/:postId' element={<PostDetailPage />} />
        <Route
          path='/post/:postId/edit'
          element={<PrivateRoute Component={EditPostPage} />}
        />
        <Route path='/search' element={<SearchPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/login/forgot_password' element={<ForgotPasswordPage />} />
        <Route path='/signup' element={<SignupPage />} />
        <Route
          path='/user/profile'
          element={<PrivateRoute Component={ProfilePage} />}
        />
        <Route
          path='/user/:userId'
          element={<PrivateRoute Component={UserPage} />}
        />
        <Route path='/develop' element={<DevelopPage />} />
        <Route path='/hotel/new' element={<CreateHotelPage />} />
        <Route path='/action' element={<ActionPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/rule' element={<RulePage />} />
        <Route path='/policy' element={<PolicyPage />} />
        <Route path='/withdrawal' element={<WithdrawalPage />} />
        <Route path='/comingsoon' element={<ComingSoon />} />
        <Route path='/404' element={<NotFoundPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Provider>
  )
}

export default App
