import { Develop } from 'components/page/develop'
import { Footer } from 'layout/Footer'
import styled from 'styled-components'

const DevelopPage = () => {
  return (
    <StyledDevelop className='footer_margin'>
      <Develop />
      <Footer />
    </StyledDevelop>
  )
}

export default DevelopPage

const StyledDevelop = styled.div``
