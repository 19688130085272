import { useState, useCallback } from 'react'
import { db } from 'components/functions/firebase/config'
import {
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore'

export const useFetchMyPosts = (userId) => {
  const [posts, setPosts] = useState([])

  // ログインしているuserIdがtestの場合に投稿情報のuseIdと合致する条件
  const filter = useCallback(
    query(
      collection(db, 'posts'),
      where('userId', '==', userId),
      where('disabled', '==', false),
      orderBy('atDate', 'desc')
    ),
    [userId]
  )

  // userIdに一致する投稿情報を全て取得
  const fetchMyPosts = async () => {
    try {
      onSnapshot(filter, (querySnapshot) => {
        return setPosts(querySnapshot.docs.map((doc) => ({ ...doc.data() })))
      })
    } catch (e) {
      throw Error(e)
    }
  }
  return { fetchMyPosts, posts }
}
