import styled from 'styled-components'
import {
  useSearchPost,
  useSearchName,
  useHotelApiData,
  useSortValue,
} from 'components/functions/recoil/hooks'
import { useState, useEffect } from 'react'
import { createHotelNameList } from 'components/functions/hooks/createHotelNameList'
import { requestApi } from 'components/functions/hooks/requestApi'
import {
  fetchSortData,
  addSearchPosts,
} from 'components/functions/firebase/hooks'
import { PhotoBlock, SearchInputForm } from 'components/model/UI'
import { SearchSortForm } from './SearchSortForm'

export const Search = () => {
  const { hotelApiData, setHotelApiData } = useHotelApiData()
  const { searchPost, setSearchPost } = useSearchPost()
  const { searchName, setSearchName } = useSearchName()
  const { sortValue } = useSortValue()
  const [sortValueBackUp, setSortValueBackUp] = useState(sortValue)
  const [addButtonActive, setAddButtonActive] = useState(true)
  const { defaultData, searchData } = searchPost
  const { mainAddress, subAddress, lowPrice, highPrice } = sortValue
  const sortActive =
    searchName.trim().length >= 2 ||
    mainAddress !== '選択なし' ||
    subAddress !== '選択なし' ||
    lowPrice !== '下限なし' ||
    highPrice !== '上限なし'

  useEffect(async () => {
    setAddButtonActive(true)
    setSortValueBackUp(sortValue)
    const newSearchData = await fetchSortData(
      '選択なし',
      '選択なし',
      '下限なし',
      '上限なし',
      [],
      '',
      60
    )
    if (defaultData.length === 0) {
      setSearchPost({ ...searchPost, defaultData: newSearchData })
    } else if (newSearchData.length === 0) {
      setSearchPost({ ...searchPost, defaultData: [] })
    } else if (
      newSearchData[0].postId !== defaultData[0].postId &&
      window.scrollY === 0
    ) {
      setSearchPost({ ...searchPost, defaultData: newSearchData })
    }
  }, [])

  useEffect(() => {
    setAddButtonActive(true)
    if (
      searchName.trimStart() === '' &&
      mainAddress === '選択なし' &&
      subAddress === '選択なし' &&
      lowPrice === '下限なし' &&
      highPrice === '上限なし'
    ) {
      setHotelApiData({ ...hotelApiData, hotelNameList: [] })
      setSearchPost({ ...searchPost, searchData: [] })
      return
    }
    const timer = setTimeout(() => {
      const searchHotelData = async () => {
        const hotelData = await requestApi(searchName)
        const hotelNames = createHotelNameList(hotelData, false)
        const searchPostData = await fetchSortData(
          mainAddress,
          subAddress,
          lowPrice,
          highPrice,
          hotelNames,
          searchName,
          60
        )
        if (searchData.length === 0 || searchPostData.length === 0) {
          setSearchPost({ ...searchPost, searchData: searchPostData })
        } else if (sortValue !== sortValueBackUp) {
          setSearchPost({ ...searchPost, searchData: searchPostData })
        } else if (
          searchPostData[0].postId !== searchData[0].postId &&
          window.scrollY === 0
        ) {
          setSearchPost({ ...searchPost, searchData: searchPostData })
        }
        setHotelApiData({
          ...hotelApiData,
          hotelNameList: createHotelNameList(searchPostData, true),
        })
        setSortValueBackUp(sortValue)
      }
      searchHotelData()
    }, 1000)
    return () => clearTimeout(timer)
  }, [searchName, sortValue])

  const addDisplayPosts = async () => {
    if (
      searchName.trimStart() === '' &&
      mainAddress === '選択なし' &&
      subAddress === '選択なし' &&
      lowPrice === '下限なし' &&
      highPrice === '上限なし'
    ) {
      const newSortData = await addSearchPosts(
        mainAddress,
        subAddress,
        lowPrice,
        highPrice,
        hotelApiData.hotelNameList,
        searchName,
        60,
        defaultData
      )
      const addData = defaultData.concat(newSortData)
      setSearchPost({ ...searchPost, defaultData: addData })
      if (newSortData.length === 0) {
        setAddButtonActive(false)
      }
    } else {
      const newSortData = await addSearchPosts(
        mainAddress,
        subAddress,
        lowPrice,
        highPrice,
        hotelApiData.hotelNameList,
        searchName,
        60,
        searchData
      )
      const addData = searchData.concat(newSortData)
      setSearchPost({ ...searchPost, searchData: addData })
      if (newSortData.length === 0) {
        setAddButtonActive(false)
      }
    }
  }

  const selectWord = (selectText) => {
    if (selectText === '該当するホテルがありません') {
      return
    }
    setSearchName(selectText)
  }

  return (
    <StyledSearch>
      <div className='control'>
        <SearchInputForm
          value={searchName}
          hotelNameList={hotelApiData.hotelNameList}
          onChange={(e) => {
            setSearchName(e.target.value)
          }}
          selectWord={selectWord}
        />
        <SearchSortForm />
      </div>
      <PhotoBlock posts={sortActive ? searchData : defaultData} />
      {addButtonActive && (
        <div className='addButton center' onClick={() => addDisplayPosts()}>
          さらに読み込む
        </div>
      )}
    </StyledSearch>
  )
}

const StyledSearch = styled.div`
  padding: 0 5.3%;

  .control {
    position: sticky;
    top: 80px;
    background: ${({ theme }) => theme.background};
    box-shadow: 0 5px 5px ${({ theme }) => theme.background};
  }
  .addButton {
    margin-top: 20px;
    cursor: pointer;
  }
`
