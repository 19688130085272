import { ColorButton, InputForm, Textarea } from 'components/commons'
import { addReport } from 'components/functions/firebase/hooks'
import { useUser } from 'components/functions/recoil/hooks'
import { checkMail } from 'components/functions/hooks/checkMail'
import { serverTimestamp } from 'firebase/firestore'
import { MainHeader } from 'layout/Header/MainHeader'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const Contact = () => {
  const [info, setInfo] = useState({
    atDate: serverTimestamp(),
    role: 'user',
    content: '',
    postId: '',
    userId: '',
    mail: '',
  })

  const navigate = useNavigate()
  const { user } = useUser()
  const location = useLocation()
  const reportPostId = location.state?.postId ?? ''

  useEffect(() => {
    setInfo({ ...info, userId: user.userId })
  }, [user])

  useEffect(() => {
    setInfo({ ...info, postId: reportPostId })
  }, [reportPostId])

  return (
    <>
      <MainHeader className='ruleHeader'>
        <ColorButton
          onClick={async (e) => {
            e.preventDefault()
            if (user.userId === '' && !checkMail(info.mail)) {
              alert('メールアドレスが不正です')
              return
            }
            try {
              await addReport(info)
              alert('お問合せありがとうございます！')
              navigate('/')
            } catch (err) {
              alert('問題が発生しました')
            }
          }}
          disabled={
            (user.userId === '' && info.mail === '') || info.content === ''
          }
        >
          投稿
        </ColorButton>
      </MainHeader>
      <StyledContactForm>
        {info.role === 'hotelStaff' && <h1>ホテル運営者問い合わせフォーム</h1>}
        {!user.userId && (
          <InputForm
            required
            label='email'
            type='email'
            value={info.mail}
            onChange={(e) =>
              setInfo({
                ...info,
                mail: e.target.value,
              })
            }
          />
        )}
        <div>
          <Textarea
            required
            label='お問い合わせ内容'
            value={info.content}
            rows='7'
            onChange={(e) =>
              setInfo({
                ...info,
                content: e.target.value,
              })
            }
          />
          {info.role === 'user' ? (
            <p className='entry'>
              ホテル運営者の方は
              <span
                tabIndex='0'
                onClick={() => setInfo({ ...info, role: 'hotelStaff' })}
              >
                こちら
              </span>
            </p>
          ) : (
            <p className='entry'>
              一般利用者の方は
              <span
                tabIndex='0'
                onClick={() => setInfo({ ...info, role: 'user' })}
              >
                こちら
              </span>
            </p>
          )}
        </div>
      </StyledContactForm>
    </>
  )
}

const StyledContactForm = styled.form`
  padding: 30px 5% 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  h1 {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .entry {
    text-align: right;
    color: ${({ theme }) => theme.secondaryTextColor};
    span {
      cursor: pointer;
      border-bottom: ${({ theme }) => `1px solid ${theme.secondaryTextColor}`};
      &:focus {
        font-weight: bold;
        color: ${({ theme }) => theme.main};
      }
    }
  }
`
