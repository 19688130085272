import { fetchUser } from 'components/functions/firebase/hooks'
import { logout } from 'components/functions/firebase/hooks'

export const logoutDisabledUser = async (userId) => {
  const isDisabled = (await fetchUser(userId)).disabled
  if (isDisabled) {
    await logout()
  }
  return isDisabled
}
