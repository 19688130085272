import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ThemeProvider as ScThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { theme } from 'styles/theme'
import { GlobalStyle } from 'styles/GlobalStyle'
import SplashScreen from 'components/commons/SplashScreen'
import { AuthProvider } from 'AuthProvider'
import { LoadingProvider } from 'LoadingProvider'

export const Provider = ({ children }) => {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <MuiThemeProvider theme={theme}>
          <ScThemeProvider theme={theme}>
            <GlobalStyle />
            <SplashScreen />
            <AuthProvider>
              <LoadingProvider>{children}</LoadingProvider>
            </AuthProvider>
          </ScThemeProvider>
        </MuiThemeProvider>
      </RecoilRoot>
    </BrowserRouter>
  )
}
