import { atom } from 'recoil'

export const userState = atom({
  key: 'user_state',
  default: {
    userId: '',
    name: '',
    userIcon: '',
  },
})

export const postDataState = atom({
  key: 'postData_state',
  default: {
    hotelName: '',
    roomName: '',
    value: 50,
    price: 10000,
    comment: '',
    prefecture: '',
    city: '',
    hotelKanaName: '',
    rakutenHotelNo: '',
  },
})

export const postHotelDataState = atom({
  key: 'postHotelData_state',
  default: {
    postId: [],
    hotelName: '',
    hotelKanaName: '',
    station: '',
    url: '',
    rakutenUrl: '',
    jalanUrl: '',
    jtbUrl: '',
    prefecture: '',
    city: '',
    rakutenHotelNo: '',
    area: '',
    check: false,
  },
})

export const uploadFilesState = atom({
  key: 'uploadFiles_state',
  default: [],
})

export const fetchPostsState = atom({
  key: 'fetchPosts_state',
  default: { postsData: [], sortData: [] },
})

export const searchPostState = atom({
  key: 'searchPost_state',
  default: {
    defaultData: [],
    searchData: [],
  },
})

export const searchNameState = atom({
  key: 'searchName_state',
  default: '',
})

export const sortValueState = atom({
  key: 'sortValue_state',
  default: {
    mainAddress: '選択なし',
    subAddress: '選択なし',
    lowPrice: '下限なし',
    highPrice: '上限なし',
  },
})

export const hotelApiDataState = atom({
  key: 'hotelApiData_state',
  default: {
    hotelInfo: [],
    hotelNameList: [],
  },
})

export const isLoadingState = atom({
  key: 'isLoading_state',
  default: false,
})

export const timeLineSortValueState = atom({
  key: 'timeLineSortValue_state',
  default: {
    mainAddress: '選択なし',
    subAddress: '選択なし',
    lowPrice: '下限なし',
    highPrice: '上限なし',
  },
})
