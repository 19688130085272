import { useFetchAllUsers } from 'components/functions/firebase/hooks/useFetchAllUsers'
import { Button } from 'components/commons'
import { UsersList } from 'components/page/develop/UsersList'
import styled from 'styled-components'
import {
  changePostData,
  changeUserData,
  fetchUser,
} from 'components/functions/firebase/hooks'

export const Develop = () => {
  const { users, loading, fetchAllUsers } = useFetchAllUsers()

  const onClickFetchAllUsers = async () => {
    await fetchAllUsers()
  }

  const onClickDisableAccount = async (userId) => {
    try {
      await changeUserData(userId, 'disabled', true)
      const { posts } = await fetchUser(userId)

      // 停止したユーザーによる投稿を無効にする
      await Promise.all(
        posts.map(async (postId) => {
          await changePostData(postId, 'disabled', true)
        })
      )
      alert('ユーザーのアカウントを無効にしました')
    } catch {
      alert('エラーが発生しました')
    }
  }

  return (
    <StyledDevelop>
      <Button onClick={onClickFetchAllUsers}>
        全ユーザーのuserNameを表示する
      </Button>
      {users && (
        <UsersList
          users={users}
          loading={loading}
          onClickDisableAccount={onClickDisableAccount}
        />
      )}
    </StyledDevelop>
  )
}

const StyledDevelop = styled.div`
  Button {
    margin-bottom: 1rem;
  }
`
