import { db } from 'components/functions/firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import { getUserData } from 'components/functions/firebase/hooks'

export const getPostDetail = async (postId) => {
  try {
    const docSnap = (await getDoc(doc(db, 'posts', postId))).data()

    const user = await getUserData(docSnap.userId)
    const postData = {
      userIcon: user.userIcon,
      userName: user.userName,
      ...docSnap,
    }
    return postData
  } catch (e) {
    throw Error(e)
  }
}
