import { useNavigate } from 'react-router-dom'
import { MainHeader } from 'layout/Header/MainHeader'
import { EditPost } from 'components/page/editPost'
import { Footer } from 'layout/Footer'
import { ColorButton } from 'components/commons'
import { onSubmitPost } from 'components/functions/hooks/useOnSubmitPost'
import {
  useUser,
  usePostData,
  useUploadFiles,
  useHotelApiData,
} from 'components/functions/recoil/hooks'
import styled from 'styled-components'

const EditPostPage = () => {
  const navigate = useNavigate()
  /* ログイン済みのユーザー情報を取得 */
  const { user } = useUser()

  const { uploadFiles, setUploadFiles } = useUploadFiles()
  const { postData, setPostData } = usePostData()
  const { hotelApiData, setHotelApiData } = useHotelApiData([])

  const sendPostData = async (e) => {
    await onSubmitPost({
      e,
      user,
      postData,
      setPostData,
      uploadFiles,
      setUploadFiles,
      hotelApiData,
      setHotelApiData,
    })
    navigate('/user/profile')
  }

  return (
    <StyledEditPost className='footer_margin'>
      <MainHeader className='header_bg'>
        <ColorButton
          onClick={(e) => sendPostData(e)}
          disabled={
            !(
              postData.hotelName.trim() &&
              uploadFiles.length &&
              hotelApiData.hotelNameList.length === 1
            )
          }
        >
          編集
        </ColorButton>
      </MainHeader>
      <EditPost />
      <Footer />
    </StyledEditPost>
  )
}

export default EditPostPage

const StyledEditPost = styled.div``
