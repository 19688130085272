import { Button, InputForm } from 'components/commons'
import { useEffect } from 'react'
import { SearchInputForm } from 'components/model/UI'
import {
  usePostHotelData,
  useHotelApiData,
  useUploadFiles,
} from 'components/functions/recoil/hooks'
import { searchHotel } from 'components/functions/firebase/hooks'
import { createHotelNameList } from 'components/functions/hooks/createHotelNameList'
import { requestApi } from 'components/functions/hooks/requestApi'
import styled from 'styled-components'
import ArchiveIcon from '@mui/icons-material/Archive'

export const HotelPostForm = () => {
  const { postHotelData, setPostHotelData } = usePostHotelData()
  const { hotelApiData, setHotelApiData } = useHotelApiData([])
  const { setUploadFiles } = useUploadFiles()

  useEffect(() => {
    setPostHotelData({
      ...postHotelData,
      check: false,
    })
    if (postHotelData.hotelName.trimStart() === '') {
      setHotelApiData({ hotelInfo: [], hotelNameList: [] })
    } else {
      const timer = setTimeout(() => {
        const searchHotelData = async () => {
          const hotelData = await requestApi(postHotelData.hotelName)
          setHotelApiData({
            ...hotelApiData,
            hotelInfo: hotelData,
            hotelNameList: createHotelNameList(hotelData, false),
          })
        }
        searchHotelData()
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [postHotelData.hotelName])

  const selectWord = (selectText) => {
    if (selectText === '該当するホテルがありません') {
      return
    }
    setPostHotelData({ ...postHotelData, hotelName: selectText })
  }

  const checkHotelData = async () => {
    const hotelData = await searchHotel(postHotelData.hotelName)
    if (hotelData) {
      setPostHotelData({
        ...postHotelData,
        hotelId: hotelData.hotelId,
        hotelImg: hotelData.hotelImg,
        url: hotelData.url,
        rakutenUrl: hotelData.rakutenUrl,
        jalanUrl: hotelData.jalanUrl,
        jtbUrl: hotelData.jtbUrl,
        check: true,
      })
      setUploadFiles(hotelData.hotelImg)
    } else {
      setPostHotelData({
        ...postHotelData,
        check: true,
      })
    }
  }

  return (
    <StyledCreatePostForm>
      <SearchInputForm
        label='ホテルの名称'
        value={postHotelData.hotelName}
        hotelNameList={hotelApiData.hotelNameList}
        onChange={(e) =>
          setPostHotelData({ ...postHotelData, hotelName: e.target.value })
        }
        className='form_input'
        selectWord={selectWord}
      />
      <Button
        onClick={() => {
          checkHotelData()
        }}
        disabled={
          !postHotelData.hotelName.trim() ||
          !(hotelApiData.hotelNameList[0] === postHotelData.hotelName) ||
          !(hotelApiData.hotelNameList.length === 1)
        }
        className='getHotelButton'
      >
        <ArchiveIcon className='getHotelButton_icon' />
      </Button>

      <InputForm
        label='ホテルの公式サイト'
        value={postHotelData.url}
        onChange={(e) =>
          setPostHotelData({ ...postHotelData, url: e.target.value })
        }
        className='form_input'
      />
      <InputForm
        label='楽天アフィリエイト'
        value={postHotelData.rakutenUrl}
        onChange={(e) =>
          setPostHotelData({ ...postHotelData, rakutenUrl: e.target.value })
        }
        className='form_input'
      />
      <InputForm
        label='じゃらんアフィリエイト'
        value={postHotelData.jalanUrl}
        onChange={(e) =>
          setPostHotelData({ ...postHotelData, jalanUrl: e.target.value })
        }
        className='form_input'
      />
      <InputForm
        label='JTBアフィリエイト'
        value={postHotelData.jtbUrl}
        onChange={(e) =>
          setPostHotelData({ ...postHotelData, jtbUrl: e.target.value })
        }
        className='form_input'
      />
    </StyledCreatePostForm>
  )
}

const StyledCreatePostForm = styled.div`
  .form_input {
    margin-top: 10px;
  }

  .getHotelButton {
    margin: 20px auto 10px auto;
    width: 30%;
    height: 35px;
    border-radius: 8px;
    &_icon {
      font-size: 2rem;
    }
  }
`
