import { useEffect, useState } from 'react'
import { auth } from 'components/functions/firebase/config'
import { Loader } from 'layout/Loader'
import { useUser } from 'components/functions/recoil/hooks'
import { logout } from 'components/functions/firebase/hooks'

export const AuthProvider = ({ children }) => {
  const [authCheck, setAuthCheck] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      setAuthCheck(true)
    })
    if (user.disabled) logout()
    return () => {
      unsubscribe()
    }
  }, [user])

  if (authCheck) {
    return <>{children}</>
  } else {
    return <Loader />
  }
}
