import { ColorButton, InputForm } from 'components/commons'
import styled from 'styled-components'

export const SubmitEmailForm = ({ email, onChange, onSubmit }) => {
  return (
    <StyledSubmitEmailForm>
      <form onSubmit={onSubmit} className='user_info_box'>
        <InputForm
          label='メールアドレス'
          type='email'
          value={email}
          onChange={onChange}
        />
        <div className='submit_button'>
          <ColorButton disabled={!email}>送信</ColorButton>
        </div>
      </form>
    </StyledSubmitEmailForm>
  )
}

const StyledSubmitEmailForm = styled.div`
  form {
    display: flex;
    flex-flow: column;
    row-gap: 10px;
    text-align: left;
  }
  .submit_button {
    width: 33%;
    margin: 26px auto 0;
  }
`
