import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import styled from 'styled-components'

export const UserCard = ({ user }) => {
  const { userId, userIcon, userName } = user
  const navigate = useNavigate()
  const queryUrl = `/user/${userId}`

  const queryUserLink = () => {
    navigate(queryUrl)
  }

  return (
    <StyledUserCard>
      <div className='user_icon' onClick={() => queryUserLink()}>
        <Avatar alt='user_icon' src={userIcon} sx={{ width: 35, height: 35 }} />
      </div>
      <div className='user_name center' onClick={() => queryUserLink()}>
        {userName}
      </div>
    </StyledUserCard>
  )
}

const StyledUserCard = styled.div`
  display: flex;
  margin-right: auto;
  .user {
    &_icon {
      margin-right: 5px;
    }
    &_name {
      font-size: 1.2rem;
      margin-left: 8px;
    }
  }
`
