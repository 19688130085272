import { createGlobalStyle } from 'styled-components'
import 'styles/reset.css'

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
    width: 100%;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  body {
    background-color: ${({ theme }) => theme.background};
    width: 100%;
    ${({ theme }) => theme.media.tab`
      max-width: 375px;
    `}
    ${({ theme }) => theme.media.pc`
      max-width: 375px;
    `}
    margin: 0 auto;
    color: ${({ theme }) => theme.textColor};

    /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
    -ms-overflow-style: none;
    /*Firefoxへの対応*/
    scrollbar-width: none;
    /*Google Chrome、Safariへの対応*/
    &::-webkit-scrollbar{
      display: none;
    }
    outline: none;
  }

  /* full size */
  .w-full{
    width: 100vw;
  }
  .h-full {
    height: 100vh;
  }

  .footer_margin{
    margin-bottom: 100px;
  }

  /* flex shortcut className */
  .sb {
    display: flex;
    justify-content: space-between;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* design */
.Neumorphism_shadow{
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
}

.drop_shadow{
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
}

.grass {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0px solid rgba(255, 255, 255, 0.18);
  box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
  -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
  border-radius: 5px;
  -webkit-border-radius: 10px;
}
`
