import { post } from 'components/functions/firebase/hooks'
import { updatePost } from 'components/functions/firebase/hooks/post'
import { logoutDisabledUser } from 'components/functions/hooks/logoutDisabledUser'

/* 投稿データの初期値 */
export const initialState = {
  hotelName: '',
  roomName: '',
  value: 50,
  price: 10000,
  comment: '',
}

/* 投稿する */
export const onSubmitPost = async ({
  e,
  user,
  postData,
  setPostData,
  uploadFiles,
  setUploadFiles,
  hotelApiData,
  setHotelApiData,
}) => {
  e.preventDefault()
  // アカウント停止されたユーザーは投稿できずログアウトされる
  const isDisabledUser = await logoutDisabledUser(user.userId)
  if (isDisabledUser) return

  if (postData.hotelName && uploadFiles.length) {
    if (
      hotelApiData.hotelNameList.length > 1 ||
      hotelApiData.hotelNameList.includes('該当するホテルがありません')
    ) {
      alert('該当するホテルがありません')
      return
    }

    if (!postData.postId) {
      try {
        await post(
          uploadFiles,
          postData,
          user,
          hotelApiData.hotelInfo[0].hotel[0].hotelBasicInfo,
          hotelApiData.hotelInfo[0].hotel[2].hotelDetailInfo
        )
        setPostData(initialState)
        setHotelApiData({ hotelInfo: [], hotelNameList: [] })
        setUploadFiles([])
        alert('投稿が完了しました！')
        return
      } catch (error) {
        alert('問題が発生して投稿できませんでした')
        return
      }
    } else {
      try {
        await updatePost(
          postData,
          uploadFiles,
          hotelApiData.hotelInfo[0].hotel[0].hotelBasicInfo,
          hotelApiData.hotelInfo[0].hotel[2].hotelDetailInfo
        )
        setHotelApiData({ hotelInfo: [], hotelNameList: [] })
        alert('編集が完了しました！')
        return
      } catch (error) {
        alert('問題が発生して投稿できませんでした')
      }
    }
  } else {
    alert('ホテル名・写真は必須です')
    return
  }
}
