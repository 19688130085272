import { Link } from 'react-router-dom'
import {
  loginWithEmailAndPassword,
  logout,
} from 'components/functions/firebase/hooks'
import { useNavigate } from 'react-router-dom'
import { setMock } from 'mock'
import { useIsLoading } from 'components/functions/recoil/hooks'
import styled from 'styled-components'

export const DevMenu = () => {
  const navigate = useNavigate()
  const login = async () => {
    const loginUserId = window.prompt('アカウントの番号を入力してください', 0)
    if (loginUserId === null) return
    try {
      await loginWithEmailAndPassword(
        `test${loginUserId}@sharm.com`,
        'password'
      )
    } catch (e) {
      throw alert(e.message)
    }
    navigate('/user/profile')
    alert('ログインしました！')
  }

  const { setIsLoading } = useIsLoading()
  const setMockData = async () => {
    if (!confirm('mockデータを追加します。よろしいですか？')) return
    setIsLoading(true)
    try {
      await setMock()
    } finally {
      setIsLoading(false)
    }
    alert('mockデータが追加されました')
  }

  return (
    <StyledDevMenu>
      <nav>
        <ul>
          <li>
            <Link to='/'>タイムライン</Link>
          </li>
          <li>
            <Link to='/post/new'>新規投稿</Link>
          </li>
          <li>
            <Link to='/test'>投稿詳細</Link>
          </li>
          <li>
            <Link to='/search'>投稿検索</Link>
          </li>
          <li>
            <Link to='/login'>ログイン</Link>
          </li>
          <li>
            <Link to='/signup'>アカウント作成</Link>
          </li>
          <li>
            <Link to='/user/profile'>マイページ</Link>
          </li>
          <li>
            <Link to='/user/test'>ユーザーページ</Link>
          </li>
          <li>
            <Link to='/develop'>開発者ページ</Link>
          </li>
          <li>
            <Link to='/hotel/new'>ホテル部屋新規投稿</Link>
          </li>
          <li>
            <button onClick={() => login()}>mockユーザーログイン</button>
          </li>
          <li>
            <button onClick={() => logout()}>ログアウト</button>
          </li>
          <li>
            <button onClick={() => setMockData()}>mockデータセット</button>
          </li>
        </ul>
      </nav>
    </StyledDevMenu>
  )
}

const StyledDevMenu = styled.div`
  position: fixed;
  top: 0;
  nav {
    position: relative;
    width: 100%;
    z-index: 99;
  }
  ul {
    position: absolute;
    top: 0;
    right: -520px;
    li {
      border-bottom: 1px solid #888;
      padding: 10px;
      cursor: pointer;
    }
  }
`
