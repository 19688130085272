import { db } from 'components/functions/firebase/config'
import { doc, getDoc } from 'firebase/firestore'

/**
 * @example
 * ```jsx
 * const hotel = fetchHotel(hotelId)
 * ```
 * @returns
 * ```jsx
 * {
 *  atDate: timestamp,
 *  city: string,
 *  hotelId: string,
 *  hotelImg: array[string],
 *  hotelKanaName: string,
 *  hotelName: string
 *  jalanUrl: string,
 *  jtbUrl: string,
 *  postId: array[string],
 *  prefecture: string,
 *  rakutenNo: string,
 *  rakutenUrl: string,
 *  station: string,
 *  url: string,
 * }
  ```
 */

export const fetchHotel = async (hotelId) => {
  try {
    const hotelDoc = await getDoc(doc(db, 'hotels', `${hotelId}`))
    return hotelDoc.data()
  } catch (error) {
    throw Error(error)
  }
}
