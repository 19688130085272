import { useState, useEffect, useCallback } from 'react'
import { db } from 'components/functions/firebase/config'
import { getDocs, query, collection, where, orderBy } from 'firebase/firestore'

export const useSameHotelPosts = (hotelId, postId) => {
  const [sameHotelPosts, setSameHotelPosts] = useState([])

  const fetchData = useCallback(
    async (hotelId, postId) => {
      const filter = query(
        collection(db, 'posts'),
        where('hotelId', '==', hotelId),
        where('disabled', '==', false),
        orderBy('atDate', 'desc')
      )
      const querySnapshot = await getDocs(filter)

      const sameHotelPostsArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }))

      // 自分自身を除外する
      return sameHotelPostsArray.filter((post) => post.postId !== postId)
    },
    [hotelId, postId]
  )

  useEffect(() => {
    let notUnmounted = true

    const setData = async (notUnmounted) => {
      let sameHotelPostsArray = []
      if (hotelId) {
        sameHotelPostsArray = await fetchData(hotelId, postId)
      }

      if (notUnmounted) {
        setSameHotelPosts(sameHotelPostsArray)
      }
    }

    setData(notUnmounted)

    return () => (notUnmounted = false)
  }, [hotelId, postId])

  return { sameHotelPosts }
}
