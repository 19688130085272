import { storage } from 'components/functions/firebase/config'
import { ref, deleteObject, getDownloadURL } from 'firebase/storage'
import { saveImage } from 'components/functions/firebase/hooks/saveImage'

export const changeProfileImg = async (userIcon, storagePath, files) => {
  try {
    if (files.length > 0) {
      const gravatarUrl = await getDownloadURL(
        ref(storage, 'users/gravatar.png')
      )
      if (gravatarUrl !== userIcon) await deleteObject(ref(storage, userIcon))
      return await saveImage(storagePath, files)
    }
  } catch (error) {
    throw Error(error)
  }
}
