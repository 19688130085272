import { db } from 'components/functions/firebase/config'
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { changeFavorite } from './changeFavorite'
import { fetchUser } from './fetchUser'

export const withdrawal = async (
  userId,
  disabledField,
  disabledValue,
  withdrawalField,
  withdrawalValue
) => {
  try {
    const userRef = doc(db, 'users', userId)
    const userData = await fetchUser(userId)
    await Promise.all(
      userData.favorite.map(async (postId) => {
        await changeFavorite(postId, userId)
      })
    )

    const newUserData = {
      [disabledField]: disabledValue,
      [withdrawalField]: withdrawalValue,
      favorite: [],
      withdrawalDay: serverTimestamp(),
    }
    await updateDoc(userRef, newUserData)
  } catch (e) {
    throw Error(e)
  }
}
