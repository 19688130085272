import { Footer } from 'layout/Footer'
import styled from 'styled-components'

const ComingSoon = () => {
  return (
    <StyledComingSoon className='footer_margin'>
      <h1>Coming Soon...</h1>
      <p>こちらの機能は近日公開予定です。</p>
      <Footer />
    </StyledComingSoon>
  )
}

export default ComingSoon

const StyledComingSoon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-top: 10vh;
    margin-bottom: 20px;
    font-size: 3rem;
    font-weight: bold;
  }
  p {
    font-size: 1.5rem;
  }
`
