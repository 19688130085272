import { db } from 'components/functions/firebase/config'
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  limit,
  startAfter,
} from 'firebase/firestore'
import { getUserData } from 'components/functions/firebase/hooks'

export const addSortPosts = async (
  mainAddress,
  subAddress,
  lowPrice,
  highPrice,
  sortData,
  limitSet
) => {
  if (
    mainAddress === '選択なし' &&
    subAddress === '選択なし' &&
    lowPrice === '下限なし' &&
    highPrice === '上限なし'
  ) {
    return []
  }
  if (sortData.length === 0) {
    return []
  }
  const priceSort = lowPrice !== '下限なし' || highPrice !== '上限なし'
  const lastSortData = sortData.slice(-1)[0]
  let queryList = [
    collection(db, 'posts'),
    where('disabled', '==', false),
    limit(limitSet),
  ]

  if (priceSort) {
    queryList.push(orderBy('price', 'asc'))
    queryList.push(startAfter(lastSortData.price))
  } else {
    queryList.push(orderBy('atDate', 'desc'))
    queryList.push(startAfter(lastSortData.atDate))
  }
  if (mainAddress !== '選択なし') {
    queryList.push(where('prefectureDivision', '==', mainAddress))
  }
  if (subAddress !== '選択なし' && mainAddress === '北海道') {
    queryList.push(where('area', '==', subAddress))
  }
  if (subAddress !== '選択なし' && mainAddress !== '北海道') {
    queryList.push(where('prefecture', '==', subAddress))
  }
  if (lowPrice !== '下限なし') {
    queryList.push(where('price', '>=', lowPrice))
  }
  if (highPrice !== '上限なし') {
    queryList.push(where('price', '<=', highPrice))
  }
  try {
    const fetchSortData = async (q) => {
      const querySnapshot = await getDocs(q)
      return await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const post = doc.data()
          const user = await getUserData(post.userId)
          const postData = {
            userIcon: user.userIcon,
            userName: user.userName,
            ...post,
          }
          return postData
        })
      )
    }

    const q = query(...queryList)
    const sortData = await fetchSortData(q)
    return sortData
  } catch (e) {
    throw Error(e)
  }
}
