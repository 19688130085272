import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useUploadFiles } from 'components/functions/recoil/hooks'
import styled from 'styled-components'

export const ImgDropAndCrop = () => {
  const [isError, setIsError] = useState(false)
  const { setUploadFiles } = useUploadFiles()

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setUploadFiles([...acceptedFiles])
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpeg, image/gif, image/jpg',
    minSize: 1,
    multiple: false,
    onDropRejected: (e) => (e ? setIsError(true) : setIsError(false)),
  })

  return (
    <DropZoneStyle>
      {isError && <p>複数ファイルは選択できません</p>}
      <div {...getRootProps()}>
        {isDragActive ? (
          <p>ファイルをドロップ</p>
        ) : (
          <p>
            ファイルをドラッグ
            <br />
            または
            <br />
            クリックして選択
          </p>
        )}
        <input {...getInputProps()}></input>
      </div>
    </DropZoneStyle>
  )
}

const DropZoneStyle = styled.div`
  p {
    text-align: center;
    color: red;
    margin-bottom: 5px;
  }
  div {
    height: 100px;
    width: 100px;
    background-color: azure;
    border: 2px dotted gray;
    display: flex;
    align-items: center;
    margin: 0 auto;
    p {
      color: #707070;
      width: 100%;
      text-align: center;
    }
  }
`
