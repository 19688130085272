import styled from 'styled-components'

export const NotFound = () => {
  return (
    <StyledNotFound>
      <div className='container'>
        <h1>404</h1>
        <h1>NotFound</h1>
      </div>
      <p>お探しのページが見つかりません。</p>
    </StyledNotFound>
  )
}

export default NotFound

const StyledNotFound = styled.div`
  text-align: center;

  .container {
    height: 120px;
    width: 255px;
    background: ${({ theme }) => theme.main};
    box-shadow: 3px 3px 3px #bec3c9, -3px -3px 3px #fff;
    border-radius: 13px;
    display: inline-block;
    margin-top: 20px;

    h1 {
      color: ${({ theme }) => theme.colors.white};
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 60px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  p {
    font-size: 1.5rem;
    padding-top: 35px;
  }
`
