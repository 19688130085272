import { db } from 'components/functions/firebase/config'
import { doc, getDoc } from 'firebase/firestore'

/**
 * 何らかの理由でFireStoreからユーザー情報がうまく取得できなかった場合
 * 代わりにunKnownユーザーを返す
 */
const unknownUser = {
  userName: 'ユーザー情報を取得できませんでした',
  userIcon: 'https://joeschmoe.io/api/v1/random',
}

export const getUserData = async (userId) => {
  if (!userId) return
  try {
    const postUserData = await getDoc(doc(db, 'users', userId))
    if (postUserData.exists()) {
      return postUserData.data()
    } else {
      return {
        ...unknownUser,
        userId,
      }
    }
  } catch (e) {
    switch (e.code) {
      case 'unavailable':
        throw new Error(
          'インターネットの接続がありません. 通信環境を確認してもう一度お試しください'
        )
    }
    throw new Error('予期せぬエラーが発生しました')
  }
}
