import { useRecoilState } from 'recoil'
import { timeLineSortValueState } from 'components/functions/recoil/atom'

export const useTimeLineSortValue = () => {
  const [timeLineSortValue, setTimeLineSortValue] = useRecoilState(
    timeLineSortValueState
  )

  return { timeLineSortValue, setTimeLineSortValue }
}
