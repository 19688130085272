import styled from 'styled-components'
import { Footer } from 'layout/Footer'
import { MainHeader } from 'layout/Header/MainHeader'
import { useNavigate } from 'react-router-dom'

const RulePage = () => {
  const navigate = useNavigate()
  return (
    <StyledRulePage className='footer_margin'>
      <MainHeader className='ruleHeader' />
      <div className='wrap'>
        <h1>利用規約</h1>
        <h2>第１条（総則）</h2>
        <p>
          1.&emsp;本規約は、「Sharm」サービス（以下「本サービス」という）の利用に関して、利用者が遵守すべき事項を定めたものである。
        </p>
        <p>
          2.&emsp;利用者は、本サービスの利用に関し、本規約のほか、本サービス内リンクからの移動先ウェブサイトの利用規約を遵守するものとする。
        </p>
        <p>
          3.&emsp;本規約は、利用者が本サービスを利用した場合、当該利用者が本規約に同意したものとみなす。
        </p>
        <h2>第２条（「 Sharm 」（シャルム））</h2>
        <p>
          「Sharm」は、本サービス利用者によるホテル・旅館に対する評価を共有するためのサービスである。
        </p>
        <h2>第３条（「 Sharm 」利用方法）</h2>
        <p>
          1.&emsp;利用者は、本サービスを利用するに際し、本規約等の内容を十分に確認のうえ利用するものとする。
        </p>
        <p>
          2.&emsp;利用者は、本サービス内リンクからの移動先ウェブサイトが提供するサービスを利用するときは、そのウェブサイトにて定められた規約、約款、ガイドライン、ルール等を十分に確認のうえ利用するものとする。
        </p>
        <p>
          3.&emsp;利用者は、本サービスの投稿機能、いいね機能を利用するためには、アカウント登録しなければならない。
        </p>
        <p>
          4.&emsp;利用者は、本サービスにおいて、著作権によって保護された著作物を許可なく使用してはならない。
        </p>
        <h2>第４条（連絡方法）</h2>
        <p>
          1.&emsp;利用者は、本サービスに関する問い合わせを、本サービス内に設置する問い合わせフォームを通じて行うものとする。
        </p>
        <p>
          2.&emsp;運営は、本サービスの問い合わせフォームを使用した利用者からの問い合わせに対し、必要に応じて、問い合わせ時に送信された電子メールアドレスを利用して連絡できるものとする。
        </p>
        <h2>第５条（個人情報）</h2>
        <p>
          1.&emsp;運営は、利用者の個人情報を「
          <span onClick={() => navigate('/policy')}>プライバシーポリシー</span>
          」の定めに従い取り扱うものとし、利用者は、これに同意するものとする。
        </p>
        <h2>第６条（禁止事項）</h2>
        <p>
          1.&emsp;利用者は、本サービスの利用に際して、次の各号に該当する行為（該当するおそれがあると運営が判断する行為を含む）を行わないものとする。
        </p>
        <p> （1）&emsp;本規約等に違反する行為</p>
        <p>
          （2）&emsp;本サービス、他の利用者、その他第三者に対し、その権利を侵害し、不利益を与え、または不快感を抱かせる行為
        </p>
        <p>
          （3）&emsp;不正に取得した第三者の情報を使用し、アカウントを作成および利用する行為
        </p>
        <p> （4）&emsp;運営が承認した以外の方法で本サービスを利用する行為</p>
        <p>
          （5）&emsp;スパムメール、チェーンレター、ジャンクメール等を送信する行為
        </p>
        <p> （6）&emsp;法令または公序良俗に反する行為</p>
        <p> （7）&emsp;その他運営が禁止するまたは不適切と判断する行為</p>
        <p>
          2.&emsp;運営は、利用者が前項各号の一の行為に該当すると判断した場合には、事前に通知することなく、当該投稿の削除または当該利用者に対し、本サービスのアカウント停止を行うことができるものとし、これにより当該利用者に何らかの損害が生じたとしても、運営は一切責任を負わないものとする。
        </p>

        <h2>第７条（免責事項）</h2>
        <p>
          1.&emsp;運営は、利用者の通信回線やコンピュータ等の障害によるシステムの中断・遅滞・中止・データの焼失、データへの不正アクセスにより生じた損害、および本サービスの利用に関して利用者に生じた損害について、一切の責任を負わない。
        </p>
        <p>
          2.&emsp;運営は、利用者の電子メール環境または伝達経路の不備により、運営が配信した電子メールが当該利用者に到着しなかったことにより生じた損害について、一切の責任を負わない。
        </p>
        <p>
          3.&emsp;利用者は、本サービスの利用に際し、自ら行った行為について責任を負うものとし、第三者に損害を与えた場合、自己の責任と費用負担で解決するものとする。
        </p>
        <p>
          4.&emsp;運営は、利用者が本規約等に違反したことによって生じた損害について、一切の責任を負わない。
        </p>
        <p>
          5.&emsp;運営は、本サービス内リンクからの移動先ウェブサイトにおいて、利用者に生じた損害について、一切の責任を負わない。
        </p>
        <p>
          6.&emsp;運営は、システムの定期保守や緊急保守を行う場合、システムに負荷が集中した場合、利用者のセキュリティを確保する必要が生じた場合、その他必要があると判断した場合には、事前に通知することなく本サービスの全部または一部の提供を中断または停止することができるものとする。運営は、この場合に利用者に発生した損害について、一切の責任を負わない。
        </p>
        <p>
          7.&emsp;運営は、利用者に対し、本サービスに投稿された情報の真偽、正確性、信頼性等につき一切保証しないものとし、当該情報に起因して利用者に発生した損害について、一切の責任を負わない。
        </p>
        <p>
          8.&emsp;運営は、本サービスについて、すべての情報端末に対して正常に動作することについて保証は行わない。
        </p>
        <h2>第8条（本規約等の変更）</h2>
        <p>
          1.&emsp;運営は、利用者に対する事前の通知なく本規約等を改定できるものとし、本規約等改定後は、改定後の本規約等を適用するものとする。なお、利用者が本サービスを利用した場合、改定後の本規約等に同意したものとする。
        </p>
        <p>
          2.&emsp;運営は、その判断により利用者に事前に通知・連絡することなく、本サービスの全部または一部を変更・廃止することができるものとする。
        </p>
        <h2>第9条（準拠法）</h2>
        <p>本規約は日本法に基づき解釈されるものとする。</p>

        <h2>2022年7月1日制定</h2>
        {/* <!-- Rakuten Web Services Attribution Snippet FROM HERE --> */}
        {/*  eslint-disable-next-line react/jsx-no-target-blank */}
        <a href='https://developers.rakuten.com/' target='_blank'>
          Supported by Rakuten Developers
        </a>
        {/* <!-- Rakuten Web Services Attribution Snippet TO HERE --> */}
      </div>
      <Footer />
    </StyledRulePage>
  )
}

export default RulePage

const StyledRulePage = styled.div`
  .ruleHeader {
    background: ${({ theme }) => theme.background};
  }

  .wrap {
    padding: 0 20px;
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-left: 10px;
  }
  h2 {
    font-size: 1.6rem;
    margin-top: 20px;
    font-weight: bold;
  }
  p {
    margin-top: 5px;
    font-size: 1.2rem;
  }
`
