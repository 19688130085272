/* パスワードが要件に合致しているかチェックする関数
	要件
	- 半角 英字・数字を組み合わせた8文字以上でなければならない
	- 半角 スペース・記号（!"#$%&'()-=^~¥|\@`[{;+:*]},<.>/?_）は使用可
	- 上記以外の文字列（全角文字、半角カタカナなど）は使用不可
*/

// パスワードの最低文字数の設定（Firebase Authenticationの仕様で5文字以下にはできない）
export const passwordLowestLength = 8

export const checkPassword = (password) => {
  // 仕様に合致するか判定する
  const regexp = new RegExp(
    String.raw`^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d !\"#\$%&\'\(\)\-=\^~¥\|\\@\`\[\]\{\};\+:\*,<\.>\/\?_]{${passwordLowestLength},}$`
  )
  return regexp.test(password)
}
