/**
 * ホテル名を含むオブジェクト配列からホテル名のみを配列として返す関数です。（重複する名前は統一）
 * @param hotelData ホテル名を含むオブジェクト配列
 * @param firebaseUse firebaseからのデータはtrue、apiでのデータはfalse
 * @return ホテル名の配列
 */

export const createHotelNameList = (hotelData, firebaseUse) => {
  try {
    if (hotelData === undefined) {
      return []
    }
    if (firebaseUse) {
      const hotelName = hotelData
        .map((hotel) => {
          return hotel.hotelName
        })
        .sort()
        .reduce((array, item) => {
          if (!array.includes(item)) {
            array.push(item)
          }
          return array
        }, [])
      if (hotelName.length === 0) {
        return ['該当するホテルがありません']
      } else {
        return hotelName
      }
    } else {
      const hotelName = hotelData.map((hotel) => {
        try {
          const name = hotel.hotel[0].hotelBasicInfo.hotelName
          return name
        } catch (error) {
          return
        }
      })
      if (hotelName.length === 0) {
        return ['該当するホテルがありません']
      } else {
        return hotelName
      }
    }
  } catch (error) {
    throw Error(error)
  }
}
