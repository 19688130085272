import { db, storage } from 'components/functions/firebase/config'
import { doc, setDoc, serverTimestamp } from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'

/* 新規ユーザーの追加 */
export const addUserData = async (userId, userName = 'No Name') => {
  try {
    // 初期のアイコン画像はstorageに保存されたgravatarを設定
    const storageRef = ref(storage, 'users/gravatar.png')
    const imageUrl = await getDownloadURL(storageRef)
    const userData = {
      atDate: serverTimestamp(),
      favorite: [],
      profile: '',
      posts: [],
      userIcon: imageUrl,
      userId: userId,
      userName: userName,
      disabled: false,
      withdrawal: false,
      withdrawalDay: null,
    }
    const userRef = doc(db, 'users', userId)
    await setDoc(userRef, userData)
  } catch (e) {
    throw Error(e)
  }
}
