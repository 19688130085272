import Swiper from 'react-id-swiper'
import styled from 'styled-components'
import 'swiper/css/swiper.css'

/**
 * @example
 *
 * ```jsx
 * <PhotoSlider
 *   photo={postImg}
 *   photoSize={300}
 *   borderRadius={20}
 * />
 * ```
 */

export const PhotoSlider = ({ photo, width, height, borderRadius }) => {
  const params =
    photo.length === 1
      ? {
          slidesPerView: 1,
          spaceBetween: 20,
          loop: false,
        }
      : {
          slidesPerView: 1,
          spaceBetween: 20,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        }

  return (
    <StyledPhotoSlider
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      <Swiper {...params}>
        {photo &&
          photo.map((image, index) => (
            <img src={image} alt='photo' key={index} className={'photo'} />
          ))}
      </Swiper>
    </StyledPhotoSlider>
  )
}
const StyledPhotoSlider = styled.div`
  .photo {
    border-radius: ${({ borderRadius }) => borderRadius}px;
    object-fit: cover;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  }
`
