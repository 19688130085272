import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * <Neumorphism w={500} h={50}>
 *   <p>Neumorphism Box</p>
 * </Neumorphism>
 * ```
 */

export const Neumorphism = ({ children, className, inset }) => {
  return (
    <StyledNeumorphism className={`${className}`} inset={inset}>
      {children}
    </StyledNeumorphism>
  )
}

const StyledNeumorphism = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  background: ${({ theme }) => theme.background};
  ${({ inset }) =>
    inset
      ? 'box-shadow: inset 3px 3px 3px #bec3c9, inset -3px -3px 3px #fff;'
      : 'box-shadow: 3px 3px 3px #bec3c9, -3px -3px 3px #fff;'}
`
