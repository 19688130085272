import {
  getCurrentAuthUser,
  signupWithEmailAndPassword,
} from 'components/functions/firebase/hooks'

import { auth } from 'components/functions/firebase/config'
import { googleAuth, twitterAuth } from 'components/functions/firebase/hooks'
import { SignupForm } from 'components/model/auth/SignupForm'
import { AuthButton } from 'components/model/UI/AuthButton'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { UserNameForm } from 'components/page/signup/UserNameForm'
import { ProvisionalRegistrationMessage } from 'components/model/auth/ProvisionalRegistrationMessage'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'

export const Signup = () => {
  const [isOpenSignupForm, setIsOpenSignupForm] = useState(false)
  const [isSignup, setIsSignup] = useState({ email: false, sns: false })
  const navigate = useNavigate()
  const currentAuthUser = getCurrentAuthUser()

  useEffect(() => {
    if (currentAuthUser) {
      navigate('/')
    }
  }, [])

  const onSubmit = async (e, email, password, userName) => {
    e.preventDefault()
    try {
      await signupWithEmailAndPassword(email, password, userName)
      setIsSignup({ ...isSignup, email: true })
    } catch (error) {
      alert(error.message)
      if (
        error.message ===
        '登録済みのメールアドレスです\n（退会したアドレスの場合、ログインより再開できます）'
      )
        navigate('/login')
    }
  }
  const onClickGoogleAuthButton = async () => {
    try {
      const { isSignUp } = await googleAuth()
      if (isSignUp) {
        setIsSignup({ ...isSignup, sns: true })
      } else {
        signOut(auth)
        alert('このアカウントは登録済みです')
      }
    } catch {
      return
    }
  }
  const onClickTwitterAuthButton = async () => {
    try {
      const { isSignUp } = await twitterAuth()
      if (isSignUp) {
        setIsSignup({ ...isSignup, sns: true })
      } else {
        signOut(auth)
        alert('このアカウントは登録済みです')
      }
    } catch {
      return
    }
  }

  return (
    <StyledSignup>
      {isSignup.email && <ProvisionalRegistrationMessage />}
      {isSignup.sns && <UserNameForm />}
      {!isSignup.email && !isSignup.sns && (
        <>
          {isOpenSignupForm ? (
            <div className='signup_form'>
              <SignupForm onSubmit={onSubmit} />
            </div>
          ) : (
            <h1 className='title'>Sharm</h1>
          )}
          <div className='auth_buttons'>
            {isOpenSignupForm ? (
              <p className='or'>または</p>
            ) : (
              <AuthButton onClick={() => setIsOpenSignupForm(true)}>
                メールアドレスで認証
              </AuthButton>
            )}
            <p>
              下記から認証した場合、
              <span
                className='term_of_service'
                onClick={() => navigate('/rule')}
              >
                利用規約
              </span>
              に同意したものと見なされます
            </p>
            <AuthButton onClick={onClickGoogleAuthButton}>
              Googleで認証
            </AuthButton>
            <AuthButton onClick={onClickTwitterAuthButton}>
              Twitterで認証
            </AuthButton>
            <button onClick={() => navigate('/login')} className='login_link'>
              ログインはこちら
            </button>
          </div>
        </>
      )}
    </StyledSignup>
  )
}

const StyledSignup = styled.div`
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 30px 5% 40px;
  .title {
    font-size: 6rem;
    text-align: center;
    margin-top: 10vh;
    font-family: 'Big John PRO';
    font-weight: bold;
    letter-spacing: 6px;
  }
  .logout_button {
    width: 100%;
  }
  .signup_form {
    width: 100%;
    text-align: center;
  }
  .or {
    font-size: 1.3rem;
    margin-top: 10px;
    color: #999999;
  }
  .term_of_service {
    text-decoration: underline;
    cursor: pointer;
  }
  .auth_buttons {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-flow: column;
    row-gap: 24px;
  }
  .forgot_password {
    font-size: 1.3rem;
    margin-top: 20px;
    display: inline-block;
    color: #00376b;
    cursor: pointer;
  }
  .login_link {
    display: block;
    margin-top: 10px;
    color: #999999;
    cursor: pointer;
    font-size: 1.3rem;
  }
`
