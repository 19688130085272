import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { Location } from 'akar-icons'
import { SliderWithFace } from 'components/commons'
import { checkMunicipalities } from 'components/functions/hooks/checkMunicipalities'
import { PostDate } from 'components/model/PostDate'
import styled from 'styled-components'

export const PostDetailContainer = ({ postData }) => {
  const navigate = useNavigate()
  const {
    atDate,
    comment,
    city,
    hotelName,
    prefecture,
    price,
    roomName,
    userIcon,
    userName,
    value,
    userId,
  } = postData
  const queryUrl = `/user/${userId}`

  const queryUserLink = () => {
    navigate(queryUrl)
  }

  return (
    <StyledPostDetailContainer>
      <div className='postDetail_top'>
        <Avatar
          alt='user_icon'
          src={userIcon}
          sx={{ width: 37, height: 37 }}
          onClick={() => queryUserLink()}
        />
        <div className='user_and_date'>
          <p className='userName' onClick={() => queryUserLink()}>
            {userName}
          </p>
          {atDate && <PostDate atDate={atDate} className='postDate' />}
        </div>
      </div>
      <div className='postDetail_infoWrap'>
        <p className='postDetail_hotelName'>{hotelName}</p>

        <div className='postDetail_location'>
          <Location size={18} strokeWidth={1.5} />
          <div className='postDetail_locationName'>
            {prefecture}
            {checkMunicipalities(city)}
          </div>
        </div>
        <div className='postDetail_room'>
          <div>
            <span className='postDetail_room_item'>部屋</span>
            <span>{roomName}</span>
          </div>
          <div>
            <span className='postDetail_room_item'>価格</span>
            <span>{price ? price.toLocaleString() : ''}円（1泊2日）</span>
          </div>
        </div>
        <p className='postDetail_comment'>{comment}</p>
        <SliderWithFace
          value={value ? value : 0}
          className='postDetail_slider'
        />
      </div>
    </StyledPostDetailContainer>
  )
}

const StyledPostDetailContainer = styled.div`
  .postDetail_top {
    display: flex;
    align-items: center;
  }

  .user_and_date {
    margin-left: 9px;
  }

  .userName {
    margin-bottom: 5px;
    font-size: 1.2rem;
  }

  .edit_button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .postDate {
    color: ${({ theme }) => theme.secondaryTextColor};
  }

  .postDetail_hotelName {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 3.6px;
    color: ${({ theme }) => theme.primaryTextColor};
  }

  .postDetail_location {
    display: flex;
    font-size: 1.3rem;
    letter-spacing: 1.3px;
  }

  .postDetail_locationName {
    margin-left: 5px;
  }

  .postDetail_room {
    margin: 8px 0;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .postDetail_room_item {
    margin-right: 19px;
    font-weight: 500;
  }

  .postDetail_comment {
    white-space: pre-wrap;
    margin-top: 8px;
    font-size: 1.1rem;
  }

  .postDetail_slider {
    margin-top: 15px;
  }
`
