import { useUploadFiles } from 'components/functions/recoil/hooks'
import { createFileURL } from 'components/functions/hooks/createFileURL'
import { removeFile } from 'components/functions/hooks/removeFile'
import { Dropzone } from 'components/page/createPost/imagePostForm/Dropzone'
import { Cross } from 'akar-icons'

import styled from 'styled-components'

export const ImagePostForm = () => {
  const { uploadFiles, setUploadFiles } = useUploadFiles()
  if (!uploadFiles) {
    return
  }
  const removePhotoBtn = (key) => {
    const newFiles = removeFile(uploadFiles, key)
    setUploadFiles(newFiles)
  }
  return (
    <StyledImagePostForm>
      <div className='sb preview'>
        {createFileURL(uploadFiles).map((url, index) => (
          <div key={index} className='preview_card'>
            <img src={url} alt='preview' className='preview_img' />
            <button
              onClick={() => removePhotoBtn(index)}
              className='preview_btn'
            >
              <Cross strokeWidth={6} size={10} className='preview_btn_icon' />
            </button>
          </div>
        ))}
        {uploadFiles.length < 4 ? <Dropzone /> : null}
      </div>
    </StyledImagePostForm>
  )
}

const StyledImagePostForm = styled.div`
  .preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    &_img {
      width: 42vw;
      height: 42vw;
      object-fit: cover;
      border-radius: 5px;
      ${({ theme }) => theme.media.tab`
        max-width: 160px;
        max-height: 160px;
      `}
      ${({ theme }) => theme.media.pc`
        max-width: 160px;
        max-height: 160px;
      `}
    }
    &_card {
      position: relative;
    }
    &_btn {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 23px;
      height: 23px;
      background-color: #fff;
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.black};
      &_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
    }
  }
`
