import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * <Glassmorphism w={500} h={50}>
 *   <p>Glassmorphism Box</p>
 * </Glassmorphism>
 * ```
 */

export const Glassmorphism = ({ children, className }) => {
  return (
    <StyledGlassmorphism className={`${className}`}>
      {children}
    </StyledGlassmorphism>
  )
}

const StyledGlassmorphism = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 191, 255, 0.075);
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px;
  border: 2px rgba(255, 255, 255, 0.4) solid;
  border-bottom: 2px rgba(40, 40, 40, 0.35) solid;
  border-right: 2px rgba(40, 40, 40, 0.35) solid;
  z-index: 99;
`
