import { db } from 'components/functions/firebase/config'
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore'

import { logoutDisabledUser } from 'components/functions/hooks/logoutDisabledUser'

export const changeFavorite = async (postId, userId) => {
  const isDisabledUser = await logoutDisabledUser(userId)
  if (isDisabledUser) return

  try {
    const docSnap = (await getDoc(doc(db, 'posts', postId))).data()
    const { favorite } = docSnap
    if (favorite.includes(userId)) {
      await updateDoc(doc(db, 'posts', postId), {
        favorite: arrayRemove(userId),
      })
      await updateDoc(doc(db, 'users', userId), {
        favorite: arrayRemove(postId),
      })
    } else {
      await updateDoc(doc(db, 'posts', postId), {
        favorite: arrayUnion(userId),
      })
      await updateDoc(doc(db, 'users', userId), {
        favorite: arrayUnion(postId),
      })
    }
  } catch (e) {
    throw Error(e)
  }
}
