import { useState, useEffect, useRef } from 'react'
import { SortCard } from 'components/model/UI'
import styled from 'styled-components'

/* Headerを小さくするスクロール量を指定 */
const HEADER_SCROLL_VALUE = 100

const DURATION_TIME = 200 /* Headerを小さくする時の間隔時間(ミリ秒) */
const DIFFERENT_SCROLL_PX = 10 /* Headerを小さくする時のスクロール間隔 */

/* 現在の時間（ミリ秒）を取得 */
const getTime = () => new Date().getTime()

export const TimeLineHeader = () => {
  const [isScroll, setIsScroll] = useState(false)
  const setScrollTime = useRef(getTime())
  const prevScrollY = useRef(0)

  const handleScroll = () => {
    const scrollY = Math.round(window.scrollY)
    let changeScroll = false
    if (scrollY > HEADER_SCROLL_VALUE) {
      changeScroll = true
    }

    const nowTime = getTime()
    if (
      Math.round(window.scrollY) === 0 ||
      (Math.abs(scrollY - prevScrollY.current) > DIFFERENT_SCROLL_PX && // スクロールが前回と差異がある時かつ
        nowTime - setScrollTime.current > DURATION_TIME) // 時間が経過している時
    ) {
      setScrollTime.current = nowTime
      prevScrollY.current = scrollY
      setIsScroll(changeScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <StyledTimeLineHeader>
      <div className={`wrapper ${isScroll ? 'small_header' : ''}`}>
        <h1 className='logo'>Sharm</h1>
      </div>
      <SortCard />
    </StyledTimeLineHeader>
  )
}

const StyledTimeLineHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2; // swiper表示より上に来るようにする
  .wrapper {
    height: 90px;
    padding-top: 40px;
    backdrop-filter: blur(8px);
    background-color: ${({ theme }) => theme.header.header_gray_grass};
    border-radius: 0 0 50% 50% /0 0 100% 100%;

    &.small_header {
      height: 60px;
      padding-top: 20px;
      transition: 0.3s;
    }
  }
  .logo {
    cursor: pointer;
    color: ${({ theme }) => theme.header.header_white};
    font-size: 1.9rem;
    text-align: center;
  }
`
