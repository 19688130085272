import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

export const PhotoBlock = ({ posts }) => {
  const navigate = useNavigate()
  if (!posts.length || typeof posts[0] === 'undefined') {
    return null
  }

  return (
    <StyledPhotoBlock>
      <div className='photoBlock'>
        {posts.map((item, index) => {
          return (
            <img
              key={index}
              src={item.postImg[0]}
              alt='photo'
              onClick={() => navigate(`/${item.postId}`)}
              className='photo'
            />
          )
        })}
      </div>
    </StyledPhotoBlock>
  )
}

const StyledPhotoBlock = styled.div`
  .photoBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 8px auto;

    &::after {
      content: '';
      display: block;
      width: 32%;
      height: 0;
    }
  }

  .photo {
    width: 98px;
    height: 98px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 5px;
    margin: 0 1% 20px 1%;
  }
`
