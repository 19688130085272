import { Check } from 'akar-icons'
import { ColorButton, InputForm } from 'components/commons'
import { useState } from 'react'
import styled from 'styled-components'

export const SignupForm = ({ onSubmit }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userName, setUserName] = useState('')
  const [isAcceptTerms, setIsAcceptTerms] = useState(false)

  return (
    <StyledSignupForm>
      <form onSubmit={(e) => onSubmit(e, email, password, userName)}>
        <div className='input_forms'>
          <InputForm
            label='メールアドレス'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputForm
            label='パスワード'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputForm
            label='ユーザー名'
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          ></InputForm>
          <div
            className='checkbox_wrapper'
            onClick={() => setIsAcceptTerms(!isAcceptTerms)}
          >
            <span className='checkbox'>
              {isAcceptTerms && <Check size={20} />}
            </span>
            <p htmlFor='terms' className='checkbox_label'>
              利用規約に同意する
            </p>
          </div>
        </div>
        <div className='signup_button'>
          <ColorButton
            disabled={!(email && password && userName && isAcceptTerms)}
          >
            登録
          </ColorButton>
        </div>
      </form>
    </StyledSignupForm>
  )
}

const StyledSignupForm = styled.div`
  animation-name: fade;
  animation-duration: 0.3s;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .input_forms {
    display: flex;
    flex-flow: column;
    row-gap: 20px;
    text-align: left;
  }
  .checkbox_wrapper {
    display: flex;
    margin: 0 auto;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background: ${({ theme }) => theme.background};
    box-shadow: inset 2px 2px 10px #00000029;
  }
  .checkbox_label {
    font-size: 1.3rem;
    margin-left: 11px;
    color: #999999;
  }
  .signup_button {
    width: 25%;
    margin: 26px auto 0;
  }
`
