import { FaceNeutral, FaceVeryHappy, FaceVerySad } from 'akar-icons'
import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * <SliderWithFace
 *   value={40}
 *   className='className'
 *   slideColor='orange'
 * />
 * ```
 */

export const SliderWithFace = ({ value, className }) => {
  return (
    <StyledSlider value={value} className={`${className}`}>
      <div className='center'>
        {value < 40 && <FaceVerySad size={20} />}
        {40 <= value && value < 70 && <FaceNeutral size={20} />}
        {70 <= value && <FaceVeryHappy size={20} />}
        <div className='neumorphism_slider_back'>
          <div className='neumorphism_slider_line' />
        </div>
        <div className='neumorphism_slider_pop'>{value}%</div>
      </div>
    </StyledSlider>
  )
}

const StyledSlider = styled.div`
  .neumorphism_slider_name {
    font-size: 1.1rem;
  }

  .neumorphism_slider_back {
    margin: 0 7px;
    padding: 5px;
    width: 60%;
    height: 21px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 20px;
    box-shadow: inset 0 0 10px 1px #00000029;

    .neumorphism_slider_line {
      width: ${({ value }) => value}%;
      height: 100%;
      border-radius: 20px;
      background: ${({ theme, value }) => {
        if (value < 40) return theme.slider.blue
        if (70 < value) return theme.slider.red
        return theme.slider.green
      }};
    }
  }

  .neumorphism_slider_pop {
    font-size: 1.2rem;
    font-weight: 500;
  }
`
