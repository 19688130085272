import axios from 'axios'

export const requestApi = async (inputName) => {
  if (inputName.length < 2) return

  try {
    const requestURL = process.env.REACT_APP_RAKUTEN_REQUEST_URL
    const affKey = process.env.REACT_APP_RAKUTEN_AFF_ID
    const apiKey = process.env.REACT_APP_RAKUTEN_APP_ID

    const hiragana =
      /^[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]+$/mu
    const katakana =
      /^[\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}]+$/mu
    const kanji =
      /^([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)+$/mu
    const upperAlphabet = /^([a-z])+$/mu
    const LowerAlphabet = /^([A-Z])+$/mu

    const checkWord = (firstWord, secondWord) => {
      if (firstWord === '') {
        return true
      }
      const checkHiragana =
        hiragana.test(firstWord) && hiragana.test(secondWord)
      const checkKatakana =
        katakana.test(firstWord) && katakana.test(secondWord)
      const checkKanji = kanji.test(firstWord) && kanji.test(secondWord)
      const checkUpperAlphabet =
        upperAlphabet.test(firstWord) && upperAlphabet.test(secondWord)
      const checkLowerAlphabet =
        LowerAlphabet.test(firstWord) && LowerAlphabet.test(secondWord)
      return (
        checkHiragana ||
        checkKatakana ||
        checkKanji ||
        checkUpperAlphabet ||
        checkLowerAlphabet
      )
    }

    let addSpaceWord = ''
    let beforeWord = ''
    for (const value of inputName) {
      if (!checkWord(beforeWord, value)) {
        addSpaceWord = addSpaceWord + '\t' + value
      } else {
        addSpaceWord = addSpaceWord + value
      }
      beforeWord = value
    }
    try {
      const keyword = inputName.substring(0, 39) //キーワード検索は40文字まで
      const fetchHotelData = await axios.get(
        `${requestURL}?format=json&affiliateId=${affKey}&keyword=${encodeURI(
          keyword
        )}&searchField=1&hits=10&responseType=large&elements=hotelNo%2ChotelName%2ChotelInformationUrl%2ChotelKanaName%2Caddress1%2Caddress2%2CnearestStation%2hotelMinCharge%2checkinTime%2lastCheckinTime&applicationId=${apiKey}`
      )
      return fetchHotelData.data.hotels
    } catch (e) {
      const keyword = addSpaceWord.substring(0, 39) //キーワード検索は40文字まで
      const fetchHotelData = await axios.get(
        `${requestURL}?format=json&affiliateId=${affKey}&keyword=${encodeURI(
          keyword
        )}&searchField=1&hits=10&responseType=large&elements=hotelNo%2ChotelName%2ChotelInformationUrl%2ChotelKanaName%2Caddress1%2Caddress2%2CnearestStation%2hotelMinCharge%2checkinTime%2lastCheckinTime&applicationId=${apiKey}`
      )
      return fetchHotelData.data.hotels
    }
  } catch (e) {
    return []
  }
}
