import styled from 'styled-components'

export const ProvisionalRegistrationMessage = () => {
  return (
    <StyledProvisionalRegistrationMessage>
      <h2 className='notification_title'>仮登録が完了しました</h2>
      <div className='notification_text'>
        <p>
          入力いただいたメールアドレス宛てに、本登録を行うためのURLを送付しました。
        </p>
        <p>メールのリンクをクリックして本登録を完了してください。</p>
        <p>
          しばらくしてもメールが届かない場合は、迷惑メールフォルダをご確認ください。
          迷惑メールフォルダになかった場合は、メールアドレスや認証方法に間違いがないかご確認ください。
        </p>
      </div>
    </StyledProvisionalRegistrationMessage>
  )
}

const StyledProvisionalRegistrationMessage = styled.div`
  margin: 10px;
  .notification_title {
    font-size: 2rem;
    text-align: center;
    text-decoration: underline;
  }
  .notification_text {
    font-size: 1.3rem;
    margin: 20px auto;
  }
`
