import { Action } from 'components/page/Action'
import { Footer } from 'layout/Footer'
import styled from 'styled-components'

const ActionPage = () => {
  return (
    <StyledAbout className='footer_margin'>
      <Action />
      <Footer />
    </StyledAbout>
  )
}

export default ActionPage

const StyledAbout = styled.div``
