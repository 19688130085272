import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Profile } from 'components/page/profile'
import { Footer } from 'layout/Footer'
import { MainHeader } from 'layout/Header/MainHeader'
import { ProfileButton } from 'components/page/profile/ProfileButton'
import { logout } from 'components/functions/firebase/hooks'

const ProfilePage = () => {
  return (
    <StyledProfile className='footer_margin'>
      <MainHeader className='profileHeader'>
        <ProfileButton>
          <Link to='/rule' className='moreArea_text'>
            利用規約
          </Link>
          <Link to='/policy' className='moreArea_text'>
            プライバシーポリシー
          </Link>
          <Link to='/Withdrawal' className='moreArea_text'>
            退会
          </Link>
          <Link to='/contact' className='moreArea_text'>
            お問い合わせ
          </Link>
          <p onClick={logout} className='moreArea_text'>
            ログアウト
          </p>
        </ProfileButton>
      </MainHeader>
      <Profile />
      <Footer />
    </StyledProfile>
  )
}

export default ProfilePage

const StyledProfile = styled.div`
  .profileHeader {
    background: ${({ theme }) => theme.background};
    box-shadow: 0 5px 5px ${({ theme }) => theme.background};
  }
`
