import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import {
  useFetchPosts,
  useTimeLineSortValue,
} from 'components/functions/recoil/hooks'
import styled from 'styled-components'
import { PhotoSlider } from 'components/commons'
import {
  addPostData,
  addSortPosts,
  getPostData,
  fetchSortData,
} from 'components/functions/firebase/hooks'
import { checkMunicipalities } from 'components/functions/hooks/checkMunicipalities'
import { PostDate } from 'components/model/PostDate'
import { FavButton } from 'components/model/UI/FavButton'
import { UserCard } from 'components/model/user/UserCard'
import { Location } from 'akar-icons'

export const TimeLine = () => {
  const { posts, setPosts } = useFetchPosts()
  const { timeLineSortValue, setTimeLineSortValue } = useTimeLineSortValue()
  const [sortValueBackUp, setSortValueBackUp] = useState(timeLineSortValue)
  const [addButtonActive, setAddButtonActive] = useState(true)
  const { postsData, sortData } = posts
  const { mainAddress, subAddress, lowPrice, highPrice } = timeLineSortValue
  const navigate = useNavigate()

  /* ホーム画面遷移時に最新投稿を読み取り */
  useEffect(async () => {
    setAddButtonActive(true)
    setSortValueBackUp(timeLineSortValue)
    const newPostData = await getPostData(20)
    if (postsData.length === 0) {
      setPosts({ ...posts, postsData: newPostData })
    } else if (newPostData.length === 0) {
      setPosts({ ...posts, postsData: [] })
    } else if (
      newPostData[0].postId !== postsData[0].postId &&
      window.scrollY === 0
    ) {
      setPosts({ ...posts, postsData: newPostData })
    }
  }, [])

  /* ソート実行時に条件に合致した投稿を読み取り */
  useEffect(async () => {
    setAddButtonActive(true)
    if (mainAddress === '') {
      setTimeLineSortValue({ ...timeLineSortValue, mainAddress: '選択なし' })
    }
    if (subAddress === '') {
      setTimeLineSortValue({ ...timeLineSortValue, subAddress: '選択なし' })
    }
    if (mainAddress === '選択なし' && subAddress === '選択なし') {
      return
    }
    const newSortData = await fetchSortData(
      mainAddress,
      subAddress,
      lowPrice,
      highPrice,
      [],
      '',
      20
    )
    if (sortData.length === 0 || newSortData.length === 0) {
      setPosts({ ...posts, sortData: newSortData })
    } else if (timeLineSortValue !== sortValueBackUp) {
      setPosts({ ...posts, sortData: newSortData })
    } else if (
      newSortData[0].postId !== sortData[0].postId &&
      window.scrollY === 0
    ) {
      setPosts({ ...posts, sortData: newSortData })
    }
    setSortValueBackUp(timeLineSortValue)
  }, [timeLineSortValue])

  /* 追加での読み取り */
  const addHomeData = async () => {
    if (mainAddress === '選択なし') {
      const newPostData = await addPostData(postsData, 20)
      const addData = postsData.concat(newPostData)
      setPosts({ ...posts, postsData: addData })
      if (newPostData.length === 0) {
        setAddButtonActive(false)
      }
    } else {
      const newSortData = await addSortPosts(
        mainAddress,
        subAddress,
        lowPrice,
        highPrice,
        sortData,
        20
      )
      const addData = sortData.concat(newSortData)
      setPosts({ ...posts, sortData: addData })
      if (newSortData.length === 0) {
        setAddButtonActive(false)
      }
    }
  }

  return (
    <StyledTimeLine>
      {(mainAddress === '選択なし' ? postsData : sortData).map((item) => {
        const {
          prefecture,
          city,
          atDate,
          favorite,
          hotelName,
          postId,
          postImg,
          userId,
          userIcon,
          userName,
        } = item
        return (
          <div key={postId} className='timeline Neumorphism_shadow'>
            <div className='timeline_postUser'>
              <UserCard user={{ userId, userIcon, userName }} />
            </div>

            <PhotoSlider
              photo={postImg}
              width={280}
              height={240}
              borderRadius={10}
            />

            <div className='timeline_infoWrap'>
              <button
                onClick={() => navigate(`/${postId}`)}
                className='timeline_postDetail'
              >
                {hotelName}
              </button>

              <div className='timeline_location'>
                <Location size={18} strokeWidth={1.5} />
                <div className='timeline_location-name'>
                  {prefecture}
                  {checkMunicipalities(city)}
                </div>
              </div>

              <div className='sb'>
                <PostDate atDate={atDate} className='timeline_postdate' />
                <FavButton
                  favorite={favorite}
                  postId={postId}
                  fontSize={1.3}
                  iconSize={2.0}
                  circleClass={'timeline_favorite'}
                />
              </div>
            </div>
          </div>
        )
      })}
      {addButtonActive && (
        <div className='center timeline_addData' onClick={addHomeData}>
          さらに読み込む
        </div>
      )}
    </StyledTimeLine>
  )
}

const StyledTimeLine = styled.div`
  .timeline {
    margin: 0 auto 50px auto;
    width: 320px;
    padding: 15px 15px;
    border-radius: 20px;
    overflow: hidden;

    &_postUser {
      margin-bottom: 10px;
    }

    &_infoWrap {
      margin-left: 10px;
    }

    &_postDetail {
      cursor: pointer;
      margin-top: 12px;
      margin-bottom: 10px;
      font-size: 1.6rem;
    }

    &_location {
      font-size: 1.3rem;
      letter-spacing: 2.6px;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    &_location-name {
      margin-left: 5px;
      white-space: nowrap;
    }

    &_postdate {
      margin-top: 15px;
      font-size: 1.2rem;
      letter-spacing: 2px;
      color: ${({ theme }) => theme.secondaryTextColor};
    }

    &_favorite {
      box-shadow: 7px 7px 15px rgba(114, 114, 114, 0.1),
        -7px -7px 20px rgba(255, 255, 255, 1),
        inset 0px 0px 4px rgba(255, 255, 255, 0.1),
        inset 7px 7px 15px rgba(114, 114, 114, 0),
        inset -7px -7px 20px rgba(255, 255, 255, 0);
    }
  }

  .timeline_addData {
    cursor: pointer;
  }
`
