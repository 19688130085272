import { db } from 'components/functions/firebase/config'
import { getDocs, collection, query, where } from 'firebase/firestore'

export const searchHotel = async (hotelName) => {
  try {
    const q = query(
      collection(db, 'hotels'),
      where('hotelName', '==', hotelName)
    )
    const querySnapshot = await getDocs(q)
    const hotelsDoc = querySnapshot.docs[0]
    return hotelsDoc ? hotelsDoc.data() : false
  } catch (error) {
    throw Error(error)
  }
}
