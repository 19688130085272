import {
  getCurrentAuthUser,
  // anonymousAuth,
  googleAuth,
  loginWithEmailAndPassword,
  twitterAuth,
} from 'components/functions/firebase/hooks'
import { LoginForm } from 'components/model/auth/LoginForm'
import { ProvisionalRegistrationMessage } from 'components/model/auth/ProvisionalRegistrationMessage'
import { AuthButton } from 'components/model/UI/AuthButton'
import { UserNameForm } from 'components/page/signup/UserNameForm'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Login = () => {
  const [showNameForm, setShowNameForm] = useState(false)
  const [inProvisionalRegistration, setInProvisionalRegistration] =
    useState(false)
  const [isOpenLoginForm, setIsOpenLoginForm] = useState(false)
  const [showTitle, setShowTitle] = useState(true)
  const navigate = useNavigate()
  const currentAuthUser = getCurrentAuthUser()

  useEffect(() => {
    if (currentAuthUser) {
      navigate('/')
    }
  }, [])

  const login = async (e, email, password) => {
    e.preventDefault()
    try {
      const isEmailVerified = await loginWithEmailAndPassword(email, password)
      if (isEmailVerified) {
        navigate('/')
      } else {
        setInProvisionalRegistration(!isEmailVerified)
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const providerLoginFlow = async (func) => {
    try {
      const { isSignUp } = await func
      setIsOpenLoginForm(false)
      setShowTitle(false)
      if (isSignUp) {
        setShowNameForm(isSignUp)
      } else {
        navigate('/')
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const onClickGoogleLoginButton = async () => {
    await providerLoginFlow(googleAuth())
  }

  const onClickTwitterLoginButton = async () => {
    await providerLoginFlow(twitterAuth())
  }

  // const onClickAnonymousLoginButton = async () => {
  //   await providerLoginFlow(anonymousAuth())
  // }

  return (
    <StyledLogin>
      {inProvisionalRegistration ? (
        <ProvisionalRegistrationMessage />
      ) : (
        <>
          {isOpenLoginForm && (
            <div className='login_form'>
              <LoginForm login={login} />
              <button
                onClick={() => navigate('/login/forgot_password')}
                className='forgot_password'
              >
                パスワードを忘れた場合
              </button>
            </div>
          )}
          {showTitle && <h1 className='title'>Sharm</h1>}
          {showNameForm ? (
            <UserNameForm />
          ) : (
            <div className='auth_buttons'>
              {isOpenLoginForm ? (
                <p className='or'>または</p>
              ) : (
                <AuthButton
                  onClick={() => {
                    setIsOpenLoginForm(true)
                    setShowTitle(false)
                  }}
                >
                  メールアドレスでログイン
                </AuthButton>
              )}
              <p>
                下記から認証した場合、
                <span
                  className='term_of_service'
                  onClick={() => navigate('/rule')}
                >
                  利用規約
                </span>
                に同意したものと見なされます
              </p>
              <AuthButton onClick={onClickGoogleLoginButton}>
                Googleでログイン
              </AuthButton>
              <AuthButton onClick={onClickTwitterLoginButton}>
                Twitterでログイン
              </AuthButton>
              {/* <AuthButton onClick={onClickAnonymousLoginButton}>匿名認証</AuthButton> */}
              <button
                onClick={() => navigate('/signup')}
                className='signup_link'
              >
                アカウント作成はこちら
              </button>
            </div>
          )}
        </>
      )}
      <nav>
        <Link to='/rule'>利用規約</Link>
        <Link to='/policy'>プライバシーポリシー</Link>
        <Link to='/contact'>お問い合わせ</Link>
      </nav>
    </StyledLogin>
  )
}

const StyledLogin = styled.div`
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 30px 5% 40px;
  .title {
    font-size: 6rem;
    text-align: center;
    margin-top: 10vh;
    font-family: 'Big John PRO';
    font-weight: bold;
    letter-spacing: 6px;
  }
  .logout_button {
    width: 100%;
  }
  .login_form {
    width: 100%;
    text-align: center;
  }
  .or {
    font-size: 1.3rem;
    margin-top: 10px;
    color: #999999;
  }
  .term_of_service {
    text-decoration: underline;
    cursor: pointer;
  }
  .auth_buttons {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-flow: column;
    row-gap: 24px;
  }
  .forgot_password {
    font-size: 1.3rem;
    margin-top: 20px;
    display: inline-block;
    color: #00376b;
    cursor: pointer;
  }
  .signup_link {
    display: block;
    margin-top: 10px;
    color: #999999;
    cursor: pointer;
    font-size: 1.3rem;
  }
  nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 18px;
    font-size: 12px;
    a {
      padding: 8px;
      cursor: pointer;
    }
  }
`
