import { useState } from 'react'
import { db } from 'components/functions/firebase/config'
import { collection, getDocs } from 'firebase/firestore'

export const useFetchAllUsers = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchAllUsers = async () => {
    setLoading(true)
    try {
      const usersDocs = await getDocs(collection(db, 'users'))
      const usersData = usersDocs.docs.map((doc) => ({
        ...doc.data(),
      }))
      return setUsers(usersData)
    } catch (error) {
      throw Error(error)
    } finally {
      setLoading(false)
    }
  }

  return { users, loading, fetchAllUsers }
}
