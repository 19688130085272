import { Contact } from 'components/page/contact'
import { Footer } from 'layout/Footer'
import styled from 'styled-components'

const ContactPage = () => {
  return (
    <StyledContact className='footer_margin'>
      <Contact />
      <Footer />
    </StyledContact>
  )
}

export default ContactPage

const StyledContact = styled.div``
