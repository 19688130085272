import styled from 'styled-components'

export const PostsSwitchButton = ({ disabled, switchDisabled }) => {
  return (
    <StyledPostsSwitchButton>
      <div className='button sb'>
        <div
          className={`button_post center ${!disabled && 'button_active'}`}
          onClick={switchDisabled}
        >
          <p className='button_text'>投稿</p>
        </div>
        <div
          className={`button_post center ${disabled && 'button_active'}`}
          onClick={switchDisabled}
        >
          <p className='button_text'>いいね</p>
        </div>
      </div>
    </StyledPostsSwitchButton>
  )
}

const StyledPostsSwitchButton = styled.div`
  .button {
    width: 100%;
    &_post {
      width: 50%;
      border-bottom: 2px solid #dddddd;
    }
    &_text {
      font-size: 13px;
      color: #353535;
      margin: 9.5px 0;
    }
    &_active {
      border-bottom: 2px solid #7acaff;
    }
  }
`
