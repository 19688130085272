import { Button } from 'components/commons'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

// 全てのユーザーの名前だけ並べる
// ユーザー名をクリックしたら、クリックしたユーザーのユーザーページに画面が遷移する
export const UsersList = ({ loading, users, onClickDisableAccount }) => {
  const navigate = useNavigate()
  return (
    <StyledUserList>
      <ul>
        {loading ||
          users.map((user, key) => {
            return (
              <li key={key}>
                <p
                  className={
                    user.disabled ? 'user_name disabled_user' : 'user_name'
                  }
                  onClick={() =>
                    navigate(`/user/${user.userId}`, { state: user })
                  }
                >
                  {user.userName}
                </p>
                <div className='account_stop_button'>
                  {!user.disabled && (
                    <Button onClick={() => onClickDisableAccount(user.userId)}>
                      アカウントを停止
                    </Button>
                  )}
                </div>
              </li>
            )
          })}
      </ul>
    </StyledUserList>
  )
}

const StyledUserList = styled.div`
  .user_name {
    display: inline-block;
  }
  .disabled_user {
    text-decoration: line-through;
  }
  .account_stop_button {
    width: fit-content;
    display: inline-block;
    margin-left: 10px;
  }
`
