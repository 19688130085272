import { Button, InputForm } from 'components/commons'
import { useState } from 'react'
import styled from 'styled-components'

export const ChangePasswordForm = ({ onSubmit }) => {
  const [password, setPassword] = useState({
    new: '',
    retype: '',
  })
  return (
    <StyledChangePasswordForm>
      <form onSubmit={(e) => onSubmit(e, password.new, password.retype)}>
        <InputForm
          label='新しいパスワード (半角英字と数字を組み合わせた8文字以上)'
          type='password'
          value={password.new}
          onChange={(e) => setPassword({ ...password, new: e.target.value })}
        />
        <InputForm
          label='パスワードを再入力'
          type='password'
          value={password.retype}
          onChange={(e) => setPassword({ ...password, retype: e.target.value })}
        />
        <Button disabled={!(password.new && password.retype)}>保存</Button>
      </form>
    </StyledChangePasswordForm>
  )
}

const StyledChangePasswordForm = styled.div``
