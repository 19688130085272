import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useUploadFiles } from 'components/functions/recoil/hooks'
import { CirclePlus } from 'akar-icons'
import styled from 'styled-components'

export const Dropzone = () => {
  const { uploadFiles, setUploadFiles } = useUploadFiles()
  /* 4枚までの写真が選択されたとき */
  const onDrop = useCallback((acceptedFiles) => {
    if (
      uploadFiles.length + acceptedFiles.length <= 4 &&
      acceptedFiles.length !== 0
    ) {
      setUploadFiles((uploadFiles) => [...uploadFiles, ...acceptedFiles])
    }
  }, [])

  /* 4枚以上の写真または違うファイルが選択されたとき */
  const onDropRejected = () => {
    alert('画像は最大4枚まで選択できます')
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    maxFiles: 4 - uploadFiles.length,
    onDrop,
    onDropRejected,
    noKeyboard: true,
    onClick: true,
  })
  return (
    <StyledDropzone>
      <div
        {...getRootProps({
          className: `dropzone_main center
              ${isDragAccept ? 'dropzone_accept' : ''} ${
            isDragReject ? 'dropzone_reject' : ''
          }`,
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className='dropzone_text'>ファイルをドロップしてください</div>
        ) : (
          <div className='dropzone_text'>
            <CirclePlus strokeWidth={2} size={28} className='dropzone_icons' />
          </div>
        )}
      </div>
    </StyledDropzone>
  )
}

const StyledDropzone = styled.div`
  .dropzone {
    &_main {
      width: 42vw;
      height: 42vw;
      background-color: ${({ theme }) => theme.dropzone.background};
      color: ${({ theme }) => theme.colors.gray};
      outline: none;
      transition: border 0.2s ease-in-out;
      ${({ theme }) => theme.media.tab`
        max-width: 160px;
        max-height: 160px;
      `}
      ${({ theme }) => theme.media.pc`
        max-width: 160px;
        max-height: 160px;
      `}
    }
    &_text {
      text-align: center;
    }
    &_icons {
      width: 28px;
      height: 28px;
      color: ${({ theme }) => theme.dropzone.icon};
    }
    &_accept {
      border-color: #00e676;
    }
    &_reject {
      border-color: #ff1744;
    }
  }
`
