export const searchAreaName = {
  選択なし: ['選択なし'],
  北海道: [
    '札幌',
    '千歳・支笏・苫小牧・滝川・夕張・空知',
    '函館・湯の川・大沼・奥尻',
    '小樽・キロロ・積丹・余市',
    '洞爺・室蘭・登別',
    '旭川・層雲峡・旭岳',
    '富良野・美瑛・トマム',
    '釧路・阿寒・川湯・根室',
    '帯広・十勝',
    '網走・紋別・北見・知床',
  ],
  東北: ['青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県'],
  関東: [
    '東京都',
    '神奈川県',
    '千葉県',
    '埼玉県',
    '群馬県',
    '栃木県',
    '茨城県',
  ],
  中部: [
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '愛知県',
    '静岡県',
  ],
  近畿: [
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '奈良県',
    '和歌山県',
    '兵庫県',
  ],
  中国: ['鳥取県', '島根県', '岡山県', '広島県', '山口県'],
  四国: ['香川県', '徳島県', '高知県', '愛媛県'],
  九州: [
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ],
}
