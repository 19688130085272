import Resizer from 'react-image-file-resizer'

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'blob',
      600,
      600
    )
  })
