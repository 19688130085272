import { useEffect, useState, useCallback } from 'react'
import { useUser } from 'components/functions/recoil/hooks'
import { useNavigate } from 'react-router-dom'
import { useFetchMyPosts } from 'components/functions/firebase/hooks/useFetchMyPosts'
import { ProfileContainer } from 'components/page/profile/ProfileContainer'
import { SettingProfile } from 'components/page/profile/SettingProfile'
import { PostsSwitchButton } from 'components/page/profile/PostsSwitchingButton'
import { FavPosts } from 'components/page/profile/FavPosts'
import { PostsContainer } from 'components/page/profile/PostsContainer'
import styled from 'styled-components'
import {
  getCurrentAuthUser,
  resetPassword,
} from 'components/functions/firebase/hooks'

export const Profile = () => {
  const { user } = useUser()
  const { fetchMyPosts, posts } = useFetchMyPosts(user.userId)
  const navigate = useNavigate()
  /* ユーザーの認証方法（パスワード、Google、Twitter...）を取得する
  TODO: 1アカウントが複数の方法で認証できるようにした場合はコードを修正する必要あり */
  const authUser = getCurrentAuthUser()
  const authProviderId = authUser.isAnonymous
    ? ''
    : authUser.providerData[0].providerId

  // モーダル表示しないでPostContainerをProfileSettingに切り替える
  const [editOpen, setEditOpen] = useState(false)
  const switchEditOpen = useCallback(() => setEditOpen(!editOpen), [editOpen])

  // PostsのButtonのdisabledを切り替え状態管理 カスタムフックにする
  const [disabled, setDisabled] = useState(false)
  const switchDisabled = useCallback(() => setDisabled(!disabled), [disabled])

  // 画像をクリックすると投稿詳細ページに遷移させる
  const onClickImg = (postId) => {
    navigate(`/${postId}`)
  }

  // パスワード変更メールを送信する
  const onClickPasswordResetButton = async () => {
    if (
      !confirm(
        `${authUser.email} にログインパスワードを再設定するためのメールを送信します。\nよろしいですか？`
      )
    )
      return
    await resetPassword(authUser.email)
    alert('メールを送信しました。ご確認ください。')
  }

  // 非同期処理まとめたやつ
  const request = async () => {
    await fetchMyPosts()
  }

  // プロフィール編集で変更点を反映させたらprofileを再取得
  useEffect(() => {
    request()
  }, [user])

  return (
    <StyledProfile>
      <ProfileContainer
        user={user}
        isShow={user.userId === user.userId}
        switchEditOpen={switchEditOpen}
        editOpen={editOpen}
      />

      {editOpen ? (
        <SettingProfile
          authProvider={authProviderId}
          onClickPasswordResetButton={onClickPasswordResetButton}
          user={user}
        />
      ) : (
        <>
          <PostsSwitchButton
            disabled={disabled}
            switchDisabled={switchDisabled}
          />
          {disabled ? (
            <FavPosts favorites={user.favorite} onClickImg={onClickImg} />
          ) : (
            <PostsContainer posts={posts} onClickImg={onClickImg} />
          )}
        </>
      )}
    </StyledProfile>
  )
}

export default Profile

const StyledProfile = styled.div`
  padding: 8px 20px 0 20px;
`
