import { Slider as MuiSlider } from '@mui/material'
import styled from 'styled-components'

export const Slider = ({ id, min, max, step, value, onChange }) => {
  return (
    <StyledSlider>
      <MuiSlider
        id={id}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />
      <div className='value'>
        {value}
        <span>%</span>
      </div>
    </StyledSlider>
  )
}

const StyledSlider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;

  .MuiSlider-root {
    // スライダーの背景
    width: 100%;
    height: 10px;
    padding: 5px 10px;
    margin: 8px 0;
    background-color: ${({ theme }) => theme.slider.background};
    box-shadow: inset 0 0 10px ${({ theme }) => theme.slider.shadow};
  }
  .MuiSlider-rail {
    background: none;
  }
  .MuiSlider-track {
    // スライダーの進行状況
    height: 12px;
    background-color: ${({ theme }) => theme.slider.color};
    border: none;
  }
  .MuiSlider-thumb {
    // スライダーのつまみ
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.slider.color};
    box-shadow: 0 3px 6px ${({ theme }) => theme.slider.shadow};
    &:hover {
      box-shadow: 0 0 0 8px ${({ theme }) => theme.slider.color_shadow};
    }
    &.Mui-active {
      box-shadow: 0 0 0 14px ${({ theme }) => theme.slider.color_shadow};
    }
  }
  .value {
    font-size: 1.6rem;
    width: 40px;
    color: ${({ theme }) => theme.textColorBlack};
    font-weight: middle;
    > span {
      font-size: 10px;
      margin-left: 4px;
      color: ${({ theme }) => theme.colors.gray};
    }
  }
`
