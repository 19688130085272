import { Button } from 'components/commons/Button'
import { ChevronLeft } from 'akar-icons'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const MainHeader = ({ children, className }) => {
  const navigate = useNavigate()
  return (
    <StyledMainHeader className={`${className}`}>
      <Button className='backBtn' onClick={() => navigate(-1)}>
        <ChevronLeft strokeWidth={2} size={18} />
      </Button>
      <div className='otherBtn'>{children}</div>
    </StyledMainHeader>
  )
}

const StyledMainHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2; // swiper表示より上に来るようにする
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .backBtn {
    width: 40px;
    height: 40px;
    color: ${({ theme }) => theme.header.header_black};
  }
  .otherBtn {
  }
`
