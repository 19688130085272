import styled from 'styled-components'

export const ProfileContainer = ({
  user,
  isShow,
  switchEditOpen,
  editOpen,
}) => {
  const { userIcon, userName, profile } = user
  return (
    <StyledProfileContainer>
      <div className='profile'>
        <div className='sb'>
          <img className='profile_img' src={userIcon} />
          {isShow &&
            (editOpen ? (
              <button
                className='profile_edit-passive center'
                onClick={switchEditOpen}
              >
                戻る
              </button>
            ) : (
              <button
                className='profile_edit-active center'
                onClick={switchEditOpen}
              >
                プロフィールを編集する
              </button>
            ))}
        </div>
      </div>
      <p className='profile_name'>{userName}</p>
      <p className='profile_text'>{profile}</p>
    </StyledProfileContainer>
  )
}

const StyledProfileContainer = styled.div`
  .profile {
    width: 100%;
    &_img {
      height: 70px;
      width: 70px;
      border-radius: 100%;
      object-fit: cover;
    }
    &_edit-active {
      width: 53%;
      height: 32px;
      font-size: 12px;
      letter-spacing: 2.4px;
      color: #7acaff;
      margin: auto 0;
      border: 3px solid #7acaff;
      cursor: pointer;
      border-radius: 22px;
    }
    &_edit-passive {
      width: 53%;
      height: 32px;
      font-size: 12px;
      letter-spacing: 2.4px;
      color: #7acaff;
      margin: auto 0;
      border: 3px solid #7acaff;
      cursor: pointer;
      border-radius: 22px;
    }
    &_name {
      font-size: 20px;
      margin-top: 9px;
      margin-bottom: 6px;
      color: #353535;
      letter-spacing: 4px;
      word-break: break-all;
    }
    &_text {
      color: #353535;
      font-size: 11px;
      margin-bottom: 17px;
      word-break: break-all;
    }
  }
`
