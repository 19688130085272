import {
  usePostHotelData,
  useResetPostHotelData,
} from 'components/functions/recoil/hooks'
import { Button } from 'components/commons'
import { Send } from '@mui/icons-material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import styled from 'styled-components'

export const CreatePostHotelButton = ({ onSubmit }) => {
  const { postHotelData } = usePostHotelData()
  const resetPostHotelData = useResetPostHotelData()

  return (
    <StyledCreatePostButton>
      <div className='sb createPostButton'>
        <Button onClick={resetPostHotelData} className='createPostButton_btn'>
          <RestartAltIcon className='createPostButton_btn_icon' />
        </Button>
        <Button
          onClick={(e) => onSubmit(e)}
          disabled={
            !postHotelData.hotelName.trim() || postHotelData.check === false
          }
          className='createPostButton_btn'
        >
          <Send className='createPostButton_btn_icon' />
        </Button>
      </div>
    </StyledCreatePostButton>
  )
}

const StyledCreatePostButton = styled.div`
  .createPostButton {
    column-gap: 10px;
    &_btn {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      &_icon {
        font-size: 2rem;
      }
    }
  }
`
