import { auth, db } from 'components/functions/firebase/config'
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  // signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
} from 'firebase/auth'
import {
  addUserData,
  fetchUser,
  withdrawal,
} from 'components/functions/firebase/hooks'
import {
  checkPassword,
  passwordLowestLength,
} from 'components/functions/hooks/checkPassword'
import { doc, getDoc } from 'firebase/firestore'

/* ユーザー登録用（メールアドレス＋パスワード） */
export const signupWithEmailAndPassword = async (email, password, userName) => {
  // 登録済みのユーザーであれば処理を中断する
  const fetchEmail = await fetchSignInMethodsForEmail(auth, email)

  if (0 < fetchEmail.length) {
    throw Error(
      '登録済みのメールアドレスです\n（退会したアドレスの場合、ログインより再開できます）'
    )
  }
  if (!checkPassword(password)) {
    throw Error(
      `半角英字・数字を使用した${passwordLowestLength}文字以上のパスワードを設定してください`
    )
  }
  try {
    const data = await createUserWithEmailAndPassword(auth, email, password)
    await addUserData(data.user.uid, userName)
    await sendEmailVerification(auth.currentUser)
    await signOut(auth)
  } catch {
    throw Error('【登録失敗】メールアドレスをご確認ください')
  }
}

/* 停止アカウントにログインしようとしたユーザーをログアウトさせる処理 */
const lockOutDisableUser = async () => {
  let message
  try {
    const fetchedUser = await fetchUser(auth.currentUser.uid)
    if (fetchedUser.disabled && !fetchedUser.withdrawal) {
      signOut(auth)
      message = 'アカウントが停止されています'
      throw Error
    }
    if (fetchedUser.disabled && fetchedUser.withdrawal) {
      const resetWithdrawal = confirm('アカウントを再開しますか？')
      if (resetWithdrawal) {
        await withdrawal(
          fetchedUser.userId,
          'disabled',
          false,
          'withdrawal',
          false
        )
      } else {
        signOut(auth)
        message = 'ログインをキャンセルしました'
        throw Error
      }
    }
  } catch {
    throw Error(message)
  }
}

/* メールアドレス＋パスワードの認証 */
export const loginWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    // メール認証が完了していないユーザーはログインを中止し、認証メールを送信する
    const isEmailVerified = auth.currentUser.emailVerified
    if (!isEmailVerified && process.env.NODE_ENV === 'production') {
      await sendEmailVerification(auth.currentUser)
      await signOut(auth)
      return isEmailVerified
    }
  } catch {
    throw Error('メールアドレスかパスワードが間違っています')
  }
  await lockOutDisableUser()
  return true
}

/* プロバイダ認証時共通の処理 */
const authProcess = async (func) => {
  try {
    const data = await func
    const userDoc = await getDoc(doc(db, 'users', `${data.user.uid}`))
    const isSignUp = !userDoc.exists()
    if (isSignUp) {
      // アカウント作成時の処理（Firestoreにデータを登録する）
      await addUserData(data.user.uid)
    }
    return { ...data, isSignUp }
  } catch {
    throw Error('認証に失敗しました')
  }
}

/* Google認証 */
export const googleAuth = async () => {
  const provider = new GoogleAuthProvider()
  const data = await authProcess(signInWithPopup(auth, provider))
  await lockOutDisableUser()
  return data
}
/* Twitter認証 */
export const twitterAuth = async () => {
  const provider = new TwitterAuthProvider()
  const data = await authProcess(signInWithPopup(auth, provider))
  await lockOutDisableUser()
  return data
}

/* 匿名認証 */
// export const anonymousAuth = async () => {
//   await signInAnonymously(auth)
// }

/* ログアウト */
export const logout = async () => {
  await signOut(auth)
  location.reload()
}

/* パスワード再設定メールの送信 */
export const resetPassword = async (email) => {
  await sendPasswordResetEmail(auth, email)
}
