import styled from 'styled-components'

/**
 * @example
 * @param {JSX.Element} children
 * @param {string} className - ボタンのクラス名
 * @param {void} onClick - default null
 * @param {boolean} disabled - default false
 * @return {JSX.Element}
 *
 * ```jsx
 * const [btn, setBtn] = useState(false)
 *
 * <AuthButton
 *  onClick={() => setBtn(!btn)}
 *  className='btn'
 *>
 *  Button
 *</AuthButton>
 * ```
 */

export const AuthButton = ({ children, className, onClick }) => {
  return (
    <StyledAuthButton>
      <button
        className={`drop_shadow drop_shadow_btn center ${className}`}
        onClick={onClick}
      >
        {children}
      </button>
    </StyledAuthButton>
  )
}

const StyledAuthButton = styled.div`
  .drop_shadow_btn {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-size: 1.9rem;
    border-radius: 9px;
    background: ${({ theme }) => theme.background};
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`
