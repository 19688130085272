import { Signup } from 'components/page/signup'
import styled from 'styled-components'

const SignupPage = () => {
  return (
    <StyledSignup>
      <Signup />
    </StyledSignup>
  )
}

export default SignupPage

const StyledSignup = styled.div``
