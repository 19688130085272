import { db } from 'components/functions/firebase/config'
import { doc, updateDoc } from 'firebase/firestore'

/* ユーザーによる投稿データの削除 */
export const disablePost = async (postId) => {
  try {
    const userRef = doc(db, 'posts', postId)
    const newUserData = {
      disabled: true,
    }
    await updateDoc(userRef, newUserData)
  } catch (error) {
    throw Error(error)
  }
}
