import { ForgotPassword } from 'components/page/forgotPassword'
import styled from 'styled-components'

const ForgotPasswordPage = () => {
  return (
    <StyledForgotPassword>
      <ForgotPassword />
    </StyledForgotPassword>
  )
}

export default ForgotPasswordPage

const StyledForgotPassword = styled.div``
