import styled from 'styled-components'
import { useState } from 'react'
import { InputForm } from 'components/commons'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

export const SearchInputForm = ({
  value,
  label,
  hotelNameList,
  onChange,
  selectWord,
  className,
}) => {
  const [sortListActive, setSortListActive] = useState(false)

  const handleClick = () => {
    setSortListActive(true)
  }

  const handleClose = (hotel) => {
    setSortListActive(false)
    if (hotel) {
      selectWord(hotel)
    }
  }

  return (
    <StyledSearchInputForm className={`${className}`}>
      <InputForm
        type='keyword'
        value={value}
        label={label}
        placeholder='ホテル名'
        onChange={onChange}
        onClick={handleClick}
        autoComplete='off'
        className='inputForm'
      />

      <div className={sortListActive ? '' : 'close'}>
        <div className='frame'>
          <div className='ListBox'>
            {hotelNameList.map((hotel) => (
              <ListItem component='div' disablePadding key={hotel}>
                <ListItemButton onClick={() => handleClose(hotel)}>
                  <ListItemText primary={hotel} />
                </ListItemButton>
              </ListItem>
            ))}
          </div>
        </div>
      </div>
      <div
        className={sortListActive ? 'backCloseArea' : 'close'}
        onClick={() => {
          handleClose()
        }}
      ></div>
    </StyledSearchInputForm>
  )
}

const StyledSearchInputForm = styled.div`
  .close {
    display: none;
  }

  .frame {
    position: relative;
  }
  .inputForm {
    position: relative;
    z-index: 1;
  }
  .ListBox {
    border-radius: 10px;
    background: white;
    width: 100%;
    max-height: 32vh;
    overflow: scroll;
    overflow-x: hidden;
    position: absolute;
    z-index: 1;
  }
  .backCloseArea {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
  }
`
