import styled from 'styled-components'

/**
 * @example
 * @param {JSX.Element} children
 * @param {string} className - ボタンのクラス名
 * @param {string} color - default 'blue'
 * @param {void} onClick - default null
 * @param {boolean} disabled - default false
 * @return {JSX.Element}
 *
 * ```jsx
 * const [btn, setBtn] = useState(false)
 *
 * <Button
 *   onClick={() => setBtn(!btn)}
 *   disabled={btn}
 *   className='btn'
 *   color='blue'
 * >
 *  Button
 * </Button>
 * ```
 */

export const ColorButton = ({
  children,
  className,
  onClick,
  disabled,
  color = 'blue',
}) => {
  return (
    <StyledColorButton>
      <button
        className={`color_btn center color_btn_${color} ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </StyledColorButton>
  )
}

const StyledColorButton = styled.div`
  .color_btn {
    width: 100%;
    height: 100%;
    padding: 6px 25px;
    font-size: 1.4rem;
    letter-spacing: 0.24rem;
    border-radius: 16px;
    color: ${({ theme }) => theme.button.text_color};
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &_blue {
      background-color: ${({ theme }) => theme.button.blue};
      box-shadow: 0px 0px 20px ${({ theme }) => theme.button.blue_shadow};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.button.disabled};
      box-shadow: 0px 0px 20px ${({ theme }) => theme.button.disabled_shadow};
    }
  }
`
