import { db } from 'components/functions/firebase/config'
import { doc, updateDoc } from 'firebase/firestore'

/**
 * 指定したpostIdの投稿データを更新する
 * @param {string} postId
 * @param {string} fieldName
 * @param {any} value
 */

export const changePostData = async (postId, fieldName, value) => {
  try {
    const postRef = doc(db, 'posts', postId)
    const postData = { [fieldName]: value }
    await updateDoc(postRef, postData)
  } catch (e) {
    throw Error(e)
  }
}
