import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'components/functions/recoil/hooks'
import {
  changePostData,
  withdrawal,
  fetchUser,
  getCurrentAuthUser,
} from 'components/functions/firebase/hooks'
import { ColorButton } from 'components/commons'
import { Check } from 'akar-icons'
import styled from 'styled-components'
import { Loader } from 'layout/Loader'

export const Withdrawal = () => {
  const navigate = useNavigate()
  const { user } = useUser()
  const [isAcceptTerms, setIsAcceptTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const currentAuthUser = getCurrentAuthUser()

  useEffect(() => {
    if (!currentAuthUser) {
      navigate('/')
    }
  }, [])

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      await withdrawal(user.userId, 'disabled', true, 'withdrawal', true)
      const { posts } = await fetchUser(user.userId)

      // 退会したユーザーによる投稿を無効にする
      await Promise.all(
        posts.map(async (postId) => {
          await changePostData(postId, 'disabled', true)
        })
      )
      setIsLoading(false)
      alert('退会が完了しました')
      navigate('/')
    } catch {
      setIsLoading(false)
      alert('エラーが発生しました')
    }
  }

  return (
    <StyledWithdrawal>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='input_forms'>
            <div
              className='checkbox_wrapper'
              onClick={() => setIsAcceptTerms(!isAcceptTerms)}
            >
              <span className='checkbox'>
                {isAcceptTerms && <Check size={20} />}
              </span>
              <p htmlFor='terms' className='checkbox_label'>
                これまでの投稿は削除されます
              </p>
            </div>
          </div>
          <div className='withdrawal_button'>
            <ColorButton
              onClick={() => {
                onSubmit()
              }}
              disabled={!isAcceptTerms}
            >
              退会
            </ColorButton>
          </div>
        </>
      )}
    </StyledWithdrawal>
  )
}

const StyledWithdrawal = styled.div`
  .input_forms {
    display: flex;
    flex-flow: column;
    row-gap: 20px;
    text-align: left;
  }
  .checkbox_wrapper {
    display: flex;
    margin: 0 auto;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background: ${({ theme }) => theme.background};
    box-shadow: inset 2px 2px 10px #00000029;
  }
  .checkbox_label {
    font-size: 1.3rem;
    margin-left: 11px;
    color: #999999;
  }
  .withdrawal_button {
    width: 28%;
    margin: 26px auto 0;
  }
`
