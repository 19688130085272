import { resetPassword } from 'components/functions/firebase/hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SubmitEmailForm } from 'components/page/forgotPassword/SubmitEmailForm.jsx'
import styled from 'styled-components'

export const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [isSubmitForm, setIsSubmitForm] = useState(false)
  const onSubmitResetPasswordForm = async (e) => {
    e.preventDefault()
    try {
      if (!confirm('メールを送信してよろしいですか？')) return
      await resetPassword(email)
    } catch {
      return
    } finally {
      setIsSubmitForm(true)
    }
  }

  // メールアドレスに@gmail.comが含まれるかチェックする
  const isGmail = /@gmail.com$/.test(email)

  return (
    <StyledForgotPassword>
      {isSubmitForm ? (
        <>
          <h2 className='notification_title'>
            パスワード再設定メールを送信しました
          </h2>
          <div className='notification_text'>
            <p>
              {email} にメールを送信しました。
              しばらくしてもメールが届かない場合は、迷惑メールフォルダをご確認ください。
              迷惑メールフォルダになかった場合は、メールアドレスや認証方法に間違いがないかご確認ください。
            </p>
            {isGmail && (
              <p>
                ※
                Google認証でアカウント登録されている場合、再設定メールは届きません。
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <p className='description_text'>
            パスワードを再設定します。アカウント作成時に登録したメールアドレスを入力してください。
          </p>
          <div className='reset_password_form'>
            <SubmitEmailForm
              email={email}
              onChange={(e) => setEmail(e.target.value)}
              onSubmit={onSubmitResetPasswordForm}
            />
            <p className='or'>または</p>
          </div>
        </>
      )}
      <div className='link_buttons'>
        <button onClick={() => navigate('/signup')} className='link_button'>
          新しいアカウントを作成する
        </button>
        <button onClick={() => navigate('/login')} className='link_button'>
          ログインへ戻る
        </button>
      </div>
    </StyledForgotPassword>
  )
}

const StyledForgotPassword = styled.div`
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;
  padding: 30px 5% 40px;
  font-size: 1.3rem;
  .notification_title {
    font-size: 1.8rem;
    text-align: center;
    text-decoration: underline;
  }
  .notification_text {
    margin-top: 25px;
  }
  .reset_password_form {
    margin: 20px auto;
    text-align: center;
  }
  .or {
    margin-top: 30px;
    font-size: 1.3rem;
    color: #999999;
  }
  .link_buttons {
    margin-top: 25px;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    text-align: center;
    flex-flow: column;
    row-gap: 24px;
  }
`
