import { db } from 'components/functions/firebase/config'
import { doc, collection, setDoc } from 'firebase/firestore'

export const addReport = async (info) => {
  try {
    const adminCollectionRef = collection(db, 'admin')
    const adminDocRef = doc(adminCollectionRef)
    await setDoc(adminDocRef, info)
  } catch (e) {
    throw new Error(e)
  }
}
