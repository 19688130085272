import { Button } from 'components/commons'
import { auth } from 'components/functions/firebase/config'
import { loginWithEmailAndPassword } from 'components/functions/firebase/hooks'
import {
  checkPassword,
  passwordLowestLength,
} from 'components/functions/hooks/checkPassword'
import { getParameterByName } from 'components/functions/hooks/getParameterByName'
import { ChangePasswordForm } from 'components/model/auth/ChangePasswordForm'
import {
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const Action = () => {
  const [state, setState] = useState({ mode: '', actionCode: '' })
  const navigate = useNavigate()

  useEffect(() => {
    /* クエリからパラメータを抜き出す
			mode: どのアクションから遷移したのかを表す。3つうちのいずれか
				- resetPassword
				- signIn
				- verifyEmail
			oobCode: ワンタイムパスコード
		*/
    const mode = getParameterByName('mode')
    const actionCode = getParameterByName('oobCode')

    const checkParameter = async () => {
      // 不正な方法（URL入力や認証切れのoobCode）でアクセスしたユーザーを追い出す
      if (!mode || !actionCode) return navigate('/')
      try {
        if (mode === 'resetPassword') {
          await verifyPasswordResetCode(auth, actionCode)
        }
        if (mode === 'verifyEmail') {
          // emailVerifiedパラメータをtrueにしてユーザーを更新する
          await applyActionCode(auth, actionCode)
          auth.currentUser && auth.currentUser.reload()
        }
      } catch {
        alert('無効な認証コード、またはタイムアウトしています。')
        return navigate('/')
      }
      setState({ mode, actionCode })
    }
    checkParameter()
  }, [])

  const onSubmitPassword = async (e, password, retypePassword) => {
    e.preventDefault()
    // 新しいパスワードが要件を満たすかチェックする
    if (password !== retypePassword) {
      alert('パスワードが一致しません。')
      return
    }
    if (!checkPassword(password)) {
      alert(
        `半角英字と数字を組み合わせた${passwordLowestLength}文字以上のパスワードを設定してください。`
      )
      return
    }
    // パスワードの更新処理
    try {
      const accountEmail = await verifyPasswordResetCode(auth, state.actionCode)
      await confirmPasswordReset(auth, state.actionCode, password)
      await loginWithEmailAndPassword(accountEmail, password)
      alert('新しいパスワードに変更しました。')
      navigate('/')
    } catch {
      alert(
        'エラーが発生しました。認証がタイムアウトした可能性があります。\nお手数ですが最初から操作をやり直してください。'
      )
      navigate('/')
    }
  }

  return (
    <StyledAction>
      {state.mode === 'resetPassword' && (
        <ChangePasswordForm onSubmit={onSubmitPassword} />
      )}
      {state.mode === 'verifyEmail' && (
        <>
          <h2 className='notification_title'>本登録が完了しました</h2>
          <Button onClick={() => navigate('/login')}>ログインする</Button>
        </>
      )}
    </StyledAction>
  )
}

const StyledAction = styled.div`
  .notification_title {
    margin: 10px;
    font-size: 2rem;
    text-align: center;
    text-decoration: underline;
  }
`
