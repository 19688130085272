import { Button } from 'components/commons/Button'
import styled from 'styled-components'
import { useSearchName, useSortValue } from 'components/functions/recoil/hooks'

export const SearchResetButton = ({ className }) => {
  const { setSearchName } = useSearchName()
  const { setSortValue } = useSortValue()
  const resetAction = () => {
    setSortValue({
      mainAddress: '選択なし',
      subAddress: '選択なし',
      lowPrice: '下限なし',
      highPrice: '上限なし',
    })
    setSearchName('')
  }

  return (
    <StyledSearchResetButton className={`${className}`}>
      <Button
        onClick={() => {
          resetAction()
        }}
      >
        リセット
      </Button>
    </StyledSearchResetButton>
  )
}

const StyledSearchResetButton = styled.div``
