import { Login } from 'components/page/login'
import styled from 'styled-components'

const LoginPage = () => {
  return (
    <StyledLogin>
      <Login />
    </StyledLogin>
  )
}

export default LoginPage

const StyledLogin = styled.div``
