import { useEffect } from 'react'
import { InputForm, Slider, Textarea } from 'components/commons'
import { SearchInputForm } from 'components/model/UI'
import { usePostData, useHotelApiData } from 'components/functions/recoil/hooks'
import { createHotelNameList } from 'components/functions/hooks/createHotelNameList'
import { requestApi } from 'components/functions/hooks/requestApi'
import { ImagePostForm } from 'components/page/createPost/imagePostForm'

import styled from 'styled-components'

export const PostForm = () => {
  /* ログイン済みのユーザー情報を取得 */
  const { postData, setPostData } = usePostData()
  const { hotelApiData, setHotelApiData } = useHotelApiData()

  useEffect(() => {
    if (postData.hotelName.trimStart() === '') {
      setHotelApiData({ hotelInfo: [], hotelNameList: [] })
    } else {
      const timer = setTimeout(() => {
        const searchHotelData = async () => {
          const hotelData = await requestApi(postData.hotelName)
          setHotelApiData({
            ...hotelApiData,
            hotelInfo: hotelData,
            hotelNameList: createHotelNameList(hotelData, false),
          })
        }
        searchHotelData()
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [postData.hotelName])

  const selectWord = (selectText) => {
    if (selectText === '該当するホテルがありません') {
      return
    }
    setPostData({ ...postData, hotelName: selectText })
  }

  return (
    <StyledCreatePostForm>
      <div className='column postForm_container'>
        <SearchInputForm
          className='postForm_input'
          value={postData.hotelName}
          label='ホテルの名称'
          hotelNameList={hotelApiData.hotelNameList}
          onChange={(e) => {
            setPostData({ ...postData, hotelName: e.target.value })
          }}
          selectWord={selectWord}
        />
        <div className='sb postForm_box'>
          <InputForm
            className='postForm_input'
            label='お部屋の名称'
            value={postData.roomName}
            onChange={(e) =>
              setPostData({ ...postData, roomName: e.target.value })
            }
          />
          <InputForm
            className='postForm_input price'
            label='価格'
            type='number'
            value={postData.price}
            onChange={(e) =>
              setPostData({ ...postData, price: e.target.value })
            }
          />
        </div>
        <div className='postForm_input'>
          <label htmlFor='value' className='label'>
            評価
          </label>
          <Slider
            id='value'
            value={postData.value}
            step={5}
            min={0}
            max={100}
            onChange={(e) =>
              setPostData({ ...postData, value: e.target.value })
            }
            valueLabelDisplay='auto'
          />
        </div>
        <Textarea
          label='コメント'
          value={postData.comment}
          onChange={(e) =>
            setPostData({ ...postData, comment: e.target.value })
          }
          // onKeyPress={(e) => keyPress(e)}
          className='postForm_input'
        />
        <div className='postForm_input'>
          <label htmlFor='' className='label'>
            画像
          </label>
          <ImagePostForm />
        </div>
      </div>
    </StyledCreatePostForm>
  )
}

const StyledCreatePostForm = styled.div`
  .postForm {
    &_container {
      row-gap: 24px;
      padding: 20px;
    }
    &_box {
      column-gap: 24px;
    }
    &_input {
      width: 100%;
      .label {
        display: block;
        color: ${({ theme }) => theme.colors.gray};
        font-size: 1rem;
        margin-bottom: 5px;
        letter-spacing: 1.1px;
      }
    }
  }
  .price {
    position: relative;
    > input {
      text-align: right;
      padding-right: 30px;
    }
    &::before {
      position: absolute;
      content: '円';
      right: 15px;
      bottom: 10px;
    }
  }
`
