import styled from 'styled-components'
import { useState } from 'react'
import { useSortValue } from 'components/functions/recoil/hooks'
import { searchAreaName } from './searchAreaName'
import { CirclePlusFill } from 'akar-icons'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { Chip } from '@mui/material'

export const SearchSortForm = () => {
  const [sortActive, setSortActive] = useState(false)
  const { sortValue, setSortValue } = useSortValue()
  const { mainAddress, subAddress, lowPrice, highPrice } = sortValue

  const lowPriceArray = [...Array(50).keys()].map((_, i) => (i + 1) * 1000)
  lowPriceArray.unshift('下限なし')
  const highPriceArray = [...Array(50).keys()]
    .map((_, i) => (i + 1) * 1000)
    .sort((a, b) => (a > b ? -1 : 1))
  highPriceArray.unshift('上限なし')

  return (
    <StyledSearchSortForm>
      <div className='searchSort'>
        <div className='searchSort_wrap'>
          <CirclePlusFill
            onClick={() => setSortActive(!sortActive)}
            className='searchSort_logo'
          />
          <p className='searchSort_title'>検索条件を追加</p>
        </div>
        <div>
          {lowPrice !== '下限なし' && (
            <Chip
              size='small'
              label={`${lowPrice}円以上`}
              className='searchSort_tag'
              onDelete={() =>
                setSortValue({ ...sortValue, lowPrice: '下限なし' })
              }
            />
          )}
          {highPrice !== '上限なし' && (
            <Chip
              size='small'
              label={`${highPrice}円以下`}
              className='searchSort_tag'
              onDelete={() =>
                setSortValue({ ...sortValue, highPrice: '上限なし' })
              }
            />
          )}
          {mainAddress !== '選択なし' && (
            <Chip
              size='small'
              label={mainAddress}
              className='searchSort_tag'
              onDelete={() =>
                setSortValue({
                  ...sortValue,
                  mainAddress: '選択なし',
                  subAddress: '選択なし',
                })
              }
            />
          )}
          {subAddress !== '選択なし' && (
            <Chip
              size='small'
              label={subAddress}
              className='searchSort_tag'
              onDelete={() =>
                setSortValue({ ...sortValue, subAddress: '選択なし' })
              }
            />
          )}
        </div>
      </div>

      <div className={`${sortActive ? '' : 'passive'}`}>
        <div className='searchSortCard drop_shadow'>
          <p className='searchSortCard_title'>予算</p>
          <div className='searchSortCard_inputArea sb'>
            <FormControl size='small'>
              <InputLabel>下限価格</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
                label='下限価格'
                className='searchSortCard_selectForm'
                value={lowPrice === '下限なし' ? '' : lowPrice}
              >
                {lowPriceArray.map((price) => {
                  if (
                    price < highPrice ||
                    highPrice === '上限なし' ||
                    price === '下限なし'
                  ) {
                    return (
                      <MenuItem
                        key={price}
                        value={price}
                        onClick={() =>
                          setSortValue({ ...sortValue, lowPrice: price })
                        }
                      >
                        {price}
                      </MenuItem>
                    )
                  } else {
                    return
                  }
                })}
              </Select>
            </FormControl>
            <div className='searchSortCard_waveDash'>~</div>
            <FormControl size='small'>
              <InputLabel>上限価格</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
                label='上限価格'
                className='searchSortCard_selectForm'
                value={highPrice === '上限なし' ? '' : highPrice}
              >
                {highPriceArray.map((price) => {
                  if (
                    lowPrice < price ||
                    lowPrice === '下限なし' ||
                    price === '上限なし'
                  ) {
                    return (
                      <MenuItem
                        key={price}
                        value={price}
                        onClick={() =>
                          setSortValue({ ...sortValue, highPrice: price })
                        }
                      >
                        {price}
                      </MenuItem>
                    )
                  } else {
                    return
                  }
                })}
              </Select>
            </FormControl>
          </div>

          <p className='searchSortCard_title'>場所</p>
          <div className='searchSortCard_inputArea sb'>
            <FormControl size='small'>
              <InputLabel>地域選択</InputLabel>
              <Select
                label='地域選択'
                className='searchSortCard_selectForm'
                value={mainAddress === '選択なし' ? '' : mainAddress}
              >
                {Object.keys(searchAreaName).map((pref) => {
                  return (
                    <MenuItem
                      key={pref}
                      value={pref}
                      onClick={() =>
                        setSortValue({
                          ...sortValue,
                          mainAddress: pref,
                          subAddress: '選択なし',
                        })
                      }
                    >
                      {pref}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl
              size='small'
              className={`${mainAddress === '選択なし' ? 'passive' : ''}`}
            >
              <InputLabel>詳細選択</InputLabel>
              <Select
                label='詳細選択'
                className='searchSortCard_selectForm'
                value={subAddress === '選択なし' ? '' : subAddress}
              >
                {searchAreaName[mainAddress].map((item) => {
                  return (
                    <MenuItem
                      key={item}
                      value={item}
                      onClick={() =>
                        setSortValue({ ...sortValue, subAddress: item })
                      }
                    >
                      {item}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className={sortActive ? 'closeArea' : ''}
          onClick={() => setSortActive(!sortActive)}
        ></div>
      </div>
    </StyledSearchSortForm>
  )
}

const StyledSearchSortForm = styled.div`
  position: relative;

  .searchSort {
    margin-top: 10px;
    padding-bottom: 10px;

    &_wrap {
      display: flex;
      justify-content: left;
      align-items: center;
    }
    &_logo {
      width: 22px;
      height: 22px;
      color: ${({ theme }) => theme.colors.blue};
      cursor: pointer;
    }
    &_title {
      margin-left: 8px;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.header_black};
    }

    &_tag {
      margin-top: 2.5px;
      margin-left: 5px;
    }
  }

  .searchSortCard {
    width: 100%;
    background: #ffffff;
    position: absolute;
    top: -12px;
    z-index: 3;
    padding: 15px;
    border-radius: 0px 0px 20px 20px;
    color: ${({ theme }) => theme.colors.black};
    &_title {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    &_inputArea {
      padding: 0 10px;
      margin-bottom: 20px;
    }
    &_selectForm {
      font-size: 1.6rem;
      width: 130px;
      height: 32px;
      border-radius: 8px;
    }
    &_waveDash {
      font-size: 2rem;
      margin: auto 0;
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  .passive {
    display: none;
  }

  .closeArea {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`
