import { useIsLoading } from 'components/functions/recoil/hooks'
import { Loader } from 'layout/Loader'

export const LoadingProvider = ({ children }) => {
  const { isLoading } = useIsLoading()

  if (isLoading) {
    return <Loader />
  }
  return <>{children}</>
}
