import styled from 'styled-components'
import Radio from '@mui/material/Radio'

/**
 * @example
 * ```jsx
 *  const [radioButtonValue, setRadioButtonValue] = useState('ランダム') //radioButtonの状態管理
 *  const radioButtonAction = (label) => {
 *   setRadioButtonValue(label)
 *  }
 *
 * @returns
 *  <RadioButton
 *   onClick={() => {
 *    radioButtonAction('低価格')
 *   }}
 *   radioButtonValue={radioButtonValue}
 *   label='低価格'
 *   disabled={text ? true : false}
 *   />
 *
 */

export const RadioButton = ({
  className,
  radioValue,
  onClick,
  label,
  disabled,
}) => {
  return (
    <StyledRadioButton className={className}>
      <div className='center'>
        <Radio
          disabled={disabled}
          checked={radioValue == label}
          name={label}
          onClick={onClick}
        />
      </div>
      <div htmlFor={label} className='center'>
        {label}
      </div>
    </StyledRadioButton>
  )
}

const StyledRadioButton = styled.div``
