import { getPostDetail, disablePost } from 'components/functions/firebase/hooks'
import {
  usePostData,
  useUploadFiles,
  useUser,
} from 'components/functions/recoil/hooks'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { PostForm } from 'components/page/createPost/PostForm'
import { logoutDisabledUser } from 'components/functions/hooks/logoutDisabledUser'

export const EditPost = () => {
  const postId = useParams().postId
  const navigate = useNavigate()
  const { user } = useUser()

  const { setPostData } = usePostData()
  const { setUploadFiles } = useUploadFiles()

  useEffect(async () => {
    const {
      userId,
      hotelId,
      hotelName,
      roomName,
      value,
      price,
      comment,
      postImg,
      disabled,
    } = await getPostDetail(postId)
    /* 投稿が無効になっている場合、または口コミを投稿したユーザー以外は編集ページから追い出す */
    if (disabled || user.userId !== userId) {
      navigate(`/${postId}`)
    }
    /* 投稿データをRecoilに入れることでフォームの初期値になる */
    setPostData({ postId, hotelId, hotelName, roomName, value, price, comment })
    setUploadFiles(postImg)
  }, [])

  const onDelete = async () => {
    const deleteConfirmation = confirm('この投稿を削除しますか？')
    try {
      const isDisabledUser = await logoutDisabledUser(user.userId)
      if (isDisabledUser) return

      if (deleteConfirmation) {
        await disablePost(postId)
        navigate('/user/profile')
      }
    } catch (error) {
      throw Error(error)
    }
  }

  return (
    <StyledEditPost>
      <PostForm />
      <div className='delete_btn_wrap'>
        <button className='delete_btn' onClick={() => onDelete()}>
          投稿を削除する
        </button>
      </div>
    </StyledEditPost>
  )
}

const StyledEditPost = styled.div`
  .editPost_box {
    margin-bottom: 20px;
  }
  .delete_btn {
    color: ${({ theme }) => theme.colors.gray};
    &_wrap {
      text-align: center;
    }
  }
`
