import { useEffect } from 'react'
import { usePostData, useUploadFiles } from 'components/functions/recoil/hooks'
import { initialState } from 'components/functions/hooks/useOnSubmitPost'
import { PostForm } from 'components/page/createPost/PostForm'
import styled from 'styled-components'

export const CreatePost = () => {
  const { setUploadFiles } = useUploadFiles()
  const { setPostData } = usePostData()

  /* Recoilの初期化 */
  useEffect(() => {
    setPostData(initialState)
    setUploadFiles([])
  }, [])

  return (
    <StyledCreatePost>
      <PostForm />
    </StyledCreatePost>
  )
}

const StyledCreatePost = styled.div`
  .createPost_box {
    margin-bottom: 20px;
  }
`
