import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * <Slider
 *   value={40}
 *   className='className'
 *   slideColor='orange'
 * />
 * ```
 */

export const NeumorphismSlider = ({ value, className, slideColor }) => {
  return (
    <StyledSlider
      value={value}
      className={`${className}`}
      slideColor={slideColor}
    >
      <div className='center'>
        <div className='neumorphism_slider_name'>評価</div>
        <div className='neumorphism_slider_back'>
          <div className='neumorphism_slider_line' />
        </div>
        <div className='neumorphism_slider_pop'>{value}%</div>
      </div>
    </StyledSlider>
  )
}

const StyledSlider = styled.div`
  .neumorphism_slider_name {
    font-size: 1.1rem;
  }

  .neumorphism_slider_back {
    margin-right: 3px;
    margin-left: 3px;
    width: 230px;
    height: 18px;
    background-color: ${({ theme }) => theme.background};
    border-radius: 20px;
    border: 4px solid #f3f4f7;
    box-shadow: 2px 2px 4px rgba(55, 84, 170, 0.15),
      -0px -0px 0px rgba(255, 255, 255, 1),
      inset 0px 0px 4px rgba(255, 255, 255, 0),
      inset 7px 7px 15px rgba(55, 84, 170, 0.15),
      inset -7px -7px 20px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, 0.2);

    .neumorphism_slider_line {
      width: ${({ value }) => value}%;
      height: 100%;
      border-radius: 20px;
      box-shadow: inset 0 1px 4px #8c8c8c;
      background: ${({ slideColor }) => slideColor};
    }
  }

  .neumorphism_slider_pop {
    position: relative;
    left: 3px;
    top: 1px;
    font-size: 1.2rem;
    font-weight: 500;
  }
`
