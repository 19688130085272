import { MainHeader } from 'layout/Header/MainHeader'
import { CreatePost } from 'components/page/createPost'
import { Footer } from 'layout/Footer'
import { ColorButton } from 'components/commons'
import { onSubmitPost } from 'components/functions/hooks/useOnSubmitPost'
import {
  useUser,
  usePostData,
  useUploadFiles,
  useHotelApiData,
} from 'components/functions/recoil/hooks'
import styled from 'styled-components'

const CreatePostPage = () => {
  /* ログイン済みのユーザー情報を取得 */
  const { user } = useUser()

  const { uploadFiles, setUploadFiles } = useUploadFiles()
  const { postData, setPostData } = usePostData()
  const { hotelApiData, setHotelApiData } = useHotelApiData([])

  const sendPostData = async (e) => {
    await onSubmitPost({
      e,
      user,
      postData,
      setPostData,
      uploadFiles,
      setUploadFiles,
      hotelApiData,
      setHotelApiData,
    })
  }

  return (
    <StyledCreatePost className='footer_margin'>
      <MainHeader className='header_bg'>
        <ColorButton
          onClick={(e) => sendPostData(e)}
          disabled={
            !(
              postData.hotelName.trim() &&
              uploadFiles.length &&
              hotelApiData.hotelNameList.length === 1
            )
          }
        >
          投稿
        </ColorButton>
      </MainHeader>
      <CreatePost />
      <Footer />
    </StyledCreatePost>
  )
}

export default CreatePostPage

const StyledCreatePost = styled.div`
  .header_bg {
    background-color: ${({ theme }) => theme.background};
    box-shadow: 0 5px 5px ${({ theme }) => theme.background};
    z-index: 0;
  }
`
