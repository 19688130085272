import { CreateHotel } from 'components/page/createHotel'
import { Footer } from 'layout/Footer'
import styled from 'styled-components'

const CreateHotelPage = () => {
  return (
    <StyledCreateHotel className='footer_margin'>
      <CreateHotel />
      <Footer />
    </StyledCreateHotel>
  )
}

export default CreateHotelPage

const StyledCreateHotel = styled.div``
