import { NotFound } from 'components/page/NotFound'
import { Footer } from 'layout/Footer'
import styled from 'styled-components'

const NotFoundPage = () => {
  return (
    <StyledNotFound className='footer_margin'>
      <NotFound />
      <Footer />
    </StyledNotFound>
  )
}

export default NotFoundPage

const StyledNotFound = styled.div``
