import styled from 'styled-components'
import dayjs from 'dayjs'

/* 
使い方

<PostDate
  atDate={atDate}
  className='post_date'
/>
*/

export const PostDate = ({ atDate, className }) => {
  const setPostDate = (date) => {
    const subDate = dayjs(new Date())
      .subtract(date.toDate())
      .subtract(9, 'hour')
    const setPostDate = () => {
      if (
        subDate.$y === 1970 &&
        subDate.$M === 0 &&
        subDate.$D === 1 &&
        subDate.$H === 0 &&
        subDate.$m === 0
      ) {
        return dayjs(subDate).format('s秒前')
      } else if (
        subDate.$y === 1970 &&
        subDate.$M === 0 &&
        subDate.$D === 1 &&
        subDate.$H === 0
      ) {
        return dayjs(subDate).format('m分前')
      } else if (subDate.$y === 1970 && subDate.$M === 0 && subDate.$D === 1) {
        return dayjs(subDate).format('H時間前')
      } else if (subDate.$y === 1970 && subDate.$M === 0 && subDate.$D <= 10) {
        return dayjs(subDate).format('D日前')
      } else {
        return dayjs(date.toDate()).format('YYYY-MM-DD')
      }
    }
    return setPostDate()
  }

  return (
    <StyledSlider className={`${className}`}>
      {setPostDate(atDate)}
    </StyledSlider>
  )
}

const StyledSlider = styled.div``
