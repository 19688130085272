import { useState } from 'react'
import styled from 'styled-components'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export const LocationMenu = () => {
  const options = ['選択なし', '北海道', '東京', '大阪', '福岡', '沖縄']
  const [place, setPlace] = useState('場所')
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (option) => {
    setAnchorEl()
    option == '選択なし' ? setPlace('場所') : setPlace(option)
  }

  return (
    <StyledLocationMenu className='SearchPlaceMenu'>
      <LocationOnIcon
        className={`LocationOnIcon ${!(place == '場所') && 'IconColor'}`}
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        onClick={handleClick}
      />
      <div>{place}</div>

      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(place)}
        className='long_menu'
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '12ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </StyledLocationMenu>
  )
}

const StyledLocationMenu = styled.div`
  .SearchPlaceMenu {
    vertical-align: middle;
    display: flex;
  }
  .LocationOnIcon {
    font-size: 2rem;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .IconColor {
    color: red;
  }
`
