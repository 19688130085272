import { hotelPost } from 'components/functions/firebase/hooks'
import {
  usePostHotelData,
  useUploadFiles,
  useHotelApiData,
} from 'components/functions/recoil/hooks'
import { ImagePostForm } from 'components/page/createPost/imagePostForm'
import { HotelPostForm } from 'components/page/createHotel/HotelPostForm'
import { CreatePostHotelButton } from 'components/page/createHotel/CreatePostHotelButton'
import styled from 'styled-components'

export const CreateHotel = () => {
  const { uploadFiles, setUploadFiles } = useUploadFiles()
  const { postHotelData, setPostHotelData } = usePostHotelData()
  const { hotelApiData, setHotelApiData } = useHotelApiData()

  /* 投稿データの初期値 */
  const initialState = {
    postId: [],
    hotelName: '',
    hotelKanaName: '',
    station: '',
    url: '',
    rakutenUrl: '',
    jalanUrl: '',
    jtbUrl: '',
    prefecture: '',
    city: '',
    rakutenHotelNo: '',
    area: '',
    check: false,
  }

  /* 投稿する */
  const onSubmit = async (e) => {
    e.preventDefault()
    if (postHotelData.hotelName) {
      if (
        hotelApiData.hotelNameList.length > 1 ||
        hotelApiData.hotelNameList.includes('該当するホテルがありません')
      ) {
        alert('該当するホテルがありません')
        return
      }
      try {
        await hotelPost(
          uploadFiles,
          postHotelData,
          hotelApiData.hotelInfo[0].hotel[0].hotelBasicInfo,
          hotelApiData.hotelInfo[0].hotel[2].hotelDetailInfo
        )
        setPostHotelData(initialState)
        setHotelApiData({ hotelInfo: [], hotelNameList: [] })
        setUploadFiles([])
        alert('投稿が完了しました！')
      } catch (error) {
        throw Error(error)
      }
    } else {
      alert('ホテル名は必須です')
    }
  }

  return (
    <StyledCreateHotel>
      <h1 className='createHotel_title center'>ホテル新規投稿</h1>
      <div className='createHotel_sendButton'>
        <CreatePostHotelButton onSubmit={onSubmit} />
      </div>
      <HotelPostForm />
      <div className='createHotel_input'>
        <label htmlFor='' className='label'>
          画像
        </label>
        <ImagePostForm />
      </div>
    </StyledCreateHotel>
  )
}

const StyledCreateHotel = styled.div`
  padding: 10px 20px;
  .createHotel {
    &_title {
      font-size: 2rem;
    }
    &_sendButton {
      display: flex;
      justify-content: right;
      align-items: center;
    }

    &_input {
      width: 100%;
      margin-top: 10px;
      .label {
        display: block;
        color: ${({ theme }) => theme.colors.gray};
        font-size: 1rem;
        margin-bottom: 5px;
        letter-spacing: 1.1px;
      }
    }
  }
`
