import styled from 'styled-components'
import { CheckBox, Box } from 'akar-icons'

export const Checkbox = ({
  address,
  pref,
  className,
  sortValue,
  setSortValue,
}) => {
  return (
    <StyledCheckbox className={className}>
      <div className='radioButton'>
        <div className='radioButton_Box radioButton_mainBox'>
          {sortValue.mainAddress === pref ? (
            <CheckBox
              strokeWidth={1.5}
              size={24}
              onClick={() =>
                setSortValue({ ...sortValue, mainAddress: '', subAddress: '' })
              }
            />
          ) : (
            <Box
              strokeWidth={1.5}
              size={24}
              onClick={() =>
                setSortValue({
                  ...sortValue,
                  mainAddress: pref,
                  subAddress: '',
                })
              }
            />
          )}
          <p className='mainAddress'>{pref}</p>
          {sortValue.mainAddress === pref && <div className='triangle' />}
        </div>
        <div className='radioButton_subWrap'>
          {sortValue.mainAddress === pref &&
            address[pref].map((city) => {
              return sortValue.subAddress === city ? (
                <div className='radioButton_Box radioButton_subBox' key={city}>
                  <CheckBox
                    strokeWidth={1.5}
                    size={20}
                    onClick={() =>
                      setSortValue({ ...sortValue, subAddress: '' })
                    }
                  />
                  <p className='subAddress'>{city}</p>
                </div>
              ) : (
                <div className='radioButton_Box radioButton_subBox' key={city}>
                  <Box
                    key={city}
                    strokeWidth={1.5}
                    size={20}
                    onClick={() =>
                      setSortValue({ ...sortValue, subAddress: city })
                    }
                  />
                  <p className='subAddress'>{city}</p>
                </div>
              )
            })}
        </div>
      </div>
    </StyledCheckbox>
  )
}

const StyledCheckbox = styled.div`
  .radioButton {
    display: flex;

    &_subWrap {
      position: absolute;
      left: 150px;
    }

    &_Box {
      display: flex;
      justify-content: left;
      align-items: center;
    }

    &_mainBox {
      margin-bottom: 12px;
    }
    &_subBox {
      margin-top: 2px;
      margin-bottom: 10px;
    }
  }

  .mainAddress {
    margin: 0 9px;
    letter-spacing: 3px;
  }
  .subAddress {
    font-size: 1.3rem;
    margin-left: 7px;
    letter-spacing: 2.6px;
  }

  .triangle {
    width: 4px;
    height: 4px;
    background: ${({ theme }) => theme.header.header_black};
    position: relative;
    border-top-left-radius: 30%;
    transform: rotate(120deg) skewY(30deg) scaleX(0.86666);
    &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      background: ${({ theme }) => theme.header.header_black};
      border-top-right-radius: 30%;
      transform: skewX(-45deg) translateX(50%);
    }
    &:after {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      background: ${({ theme }) => theme.header.header_black};
      border-bottom-left-radius: 30%;
      transform: skewY(-45deg) translateY(50%);
    }
  }
`
