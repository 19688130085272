import { db } from 'components/functions/firebase/config'
import { doc, getDoc } from 'firebase/firestore'

/**
 * @example
 * ```jsx
 * const user = fetchUser(userId)
 * ```
 * @returns
 * ```jsx
 * {
 *  userId: string,
 *  userIcon: string,
 *  userName: string,
 *  userIcon: string,
 *  profile: string,
 *  favorite: string[],
 *  posts: string[],
 *  disabled: boolean,
 * }
  ```
 */

export const fetchUser = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', `${userId}`))
    return userDoc.data()
  } catch (error) {
    throw Error(error)
  }
}
