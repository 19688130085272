import styled from 'styled-components'

export const PostsContainer = ({ posts, onClickImg }) => {
  return (
    <StyledPostsContainer>
      <div className='PostsContainer'>
        {posts.map((post, key) => {
          return (
            <img
              src={post.postImg}
              onClick={() => onClickImg(post.postId)}
              key={key}
              className='photo'
            />
          )
        })}
      </div>
    </StyledPostsContainer>
  )
}

const StyledPostsContainer = styled.div`
  .PostsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px auto;

    &::after {
      content: '';
      display: block;
      width: 32%;
      height: 0;
    }
  }

  .photo {
    width: 98px;
    height: 98px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 5px;
    margin: 0 1% 20px 1%;
  }
`
