import { db } from 'components/functions/firebase/config'
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  limit,
} from 'firebase/firestore'
import { getUserData } from 'components/functions/firebase/hooks'

export const fetchSortData = async (
  mainAddress,
  subAddress,
  lowPrice,
  highPrice,
  hotelNameList,
  searchName,
  limitSet
) => {
  const priceSort = lowPrice !== '下限なし' || highPrice !== '上限なし'

  let queryList = [
    collection(db, 'posts'),
    where('disabled', '==', false),
    limit(limitSet),
  ]

  if (priceSort) {
    queryList.push(orderBy('price', 'asc'))
  } else {
    queryList.push(orderBy('atDate', 'desc'))
  }
  if (mainAddress !== '選択なし') {
    queryList.push(where('prefectureDivision', '==', mainAddress))
  }
  if (subAddress !== '選択なし' && mainAddress === '北海道') {
    queryList.push(where('area', '==', subAddress))
  }
  if (subAddress !== '選択なし' && mainAddress !== '北海道') {
    queryList.push(where('prefecture', '==', subAddress))
  }
  if (lowPrice !== '下限なし') {
    queryList.push(where('price', '>=', lowPrice))
  }
  if (highPrice !== '上限なし') {
    queryList.push(where('price', '<=', highPrice))
  }
  if (searchName.length >= 2) {
    queryList.push(where('hotelName', 'in', hotelNameList))
  }
  try {
    const fetchSortPostData = async (q) => {
      const querySnapshot = await getDocs(q)
      return await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const post = doc.data()
          const user = await getUserData(post.userId)
          const postData = {
            userIcon: user.userIcon,
            userName: user.userName,
            ...post,
          }
          return postData
        })
      )
    }

    const q = query(...queryList)
    const sortData = await fetchSortPostData(q)
    return sortData
  } catch (e) {
    throw Error(e)
  }
}
