import { db } from 'components/functions/firebase/config'
import { doc, updateDoc } from 'firebase/firestore'

/**
 * 指定したuserIdのデータを更新する
 * @param {string} userId
 * @param {string} fieldName
 * @param {any} value
 */

export const changeUserData = async (userId, fieldName, value) => {
  try {
    const userRef = doc(db, 'users', userId)
    const userData = { [fieldName]: value }
    await updateDoc(userRef, userData)
  } catch (e) {
    throw Error(e)
  }
}
