import styled from 'styled-components'

/**
 * @example
 *
 * ```jsx
 * <Neumorphism w={500} h={50}>
 *   <p>Neumorphism Box</p>
 * </Neumorphism>
 * ```
 */

export const BackGroundCard = ({ children, className }) => {
  return (
    <StyledNeumorphism className={`${className} Neumorphism_shadow`}>
      {children}
    </StyledNeumorphism>
  )
}

const StyledNeumorphism = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  background: ${({ theme }) => theme.background};
`
