import { useUploadFiles } from 'components/functions/recoil/hooks'
import { createFileURL } from 'components/functions/hooks/createFileURL'
import { removeFile } from 'components/functions/hooks/removeFile'
import { RemoveCircle } from '@mui/icons-material'
import styled from 'styled-components'

export const ImagePreview = () => {
  const { uploadFiles, setUploadFiles } = useUploadFiles()
  if (!uploadFiles) {
    return
  }
  const removePhotoBtn = (key) => {
    const newFiles = removeFile(uploadFiles, key)
    setUploadFiles(newFiles)
  }
  return (
    <StyledImagePreview>
      <div className='center preview'>
        {createFileURL(uploadFiles).map((url, index) => (
          <div key={index} className='preview_card'>
            <img src={url} alt='preview' className='preview_img' />
            <button
              onClick={() => removePhotoBtn(index)}
              className='preview_btn'
            >
              <RemoveCircle className='preview_btn_icon' />
            </button>
          </div>
        ))}
      </div>
    </StyledImagePreview>
  )
}

const StyledImagePreview = styled.div`
  .preview {
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px;
    &_img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 20px;
    }
    &_card {
      position: relative;
    }
    &_btn {
      position: absolute;
      top: 5px;
      right: 5px;
      &_icon {
        font-size: 2.4em;
      }
    }
  }
`
