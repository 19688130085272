import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { BackGroundCard, PhotoSlider } from 'components/commons'
import { FavButton } from 'components/model/UI/FavButton'
import { getPostDetail } from 'components/functions/firebase/hooks'
import { useUser } from 'components/functions/recoil/hooks'
import { Edit } from 'akar-icons'
import { PostDetailContainer } from './PostDetailContainer'
import { fetchHotel } from 'components/functions/firebase/hooks/fetchHotel'
import { OfficialHotelInfo } from './OfficialHotelInfo'
import { requestApi } from 'components/functions/hooks/requestApi'
import { PhotoBlock } from 'components/model/UI/PhotoBlock'
import { useSameHotelPosts } from 'components/functions/firebase/hooks/useSameHotelPosts'

export const PostDetail = ({ data, setData }) => {
  const navigate = useNavigate()
  const param = useParams()
  const { user } = useUser()

  useEffect(async () => {
    // FIXME: getPostDetailの中でuseEffectする
    try {
      const postData = await getPostDetail(param.postId)
      const hotelData = await fetchHotel(postData.hotelId)
      const hotelInfo = (await requestApi(hotelData.hotelName))[0]['hotel']
      const hotelBasicInfo = hotelInfo[0]['hotelBasicInfo']
      const hotelDetailInfo = hotelInfo[2]['hotelDetailInfo']
      setData({
        post: postData,
        hotel: {
          ...hotelData,
          access: hotelBasicInfo.access,
          hotelMinCharge: hotelBasicInfo.hotelMinCharge,
          checkinTime: hotelDetailInfo.checkinTime,
          checkoutTime: hotelDetailInfo.checkoutTime,
        },
      })
      window.scroll(0, 0)
      if (postData.disabled) {
        alert('削除された投稿です')
        navigate('/')
      }
    } catch (error) {
      navigate('/404')
    }
  }, [param])

  const { favorite, hotelId, postId, postImg, userId } = data.post

  const { sameHotelPosts } = useSameHotelPosts(hotelId, postId)

  return (
    <StyledPostDetail>
      {postImg && (
        <div className='background_photo'>
          <PhotoSlider photo={postImg} height={315} borderRadius={0} />
        </div>
      )}
      <div className='postDetail'>
        <BackGroundCard className='postDetail_container'>
          {favorite && (
            <FavButton
              favorite={favorite}
              postId={postId}
              userId={userId}
              className='postDetail_favorite'
              circleClass={'drop_shadow'}
              iconSize={3}
              isCount={false}
              circleSize={52}
            />
          )}
          {userId === user.userId && (
            <Edit
              size={26}
              onClick={() => navigate(`/post/${postId}/edit`)}
              className='edit_button'
            />
          )}
          <PostDetailContainer postData={data.post} />
          <OfficialHotelInfo hotelData={data.hotel} />
          {sameHotelPosts && sameHotelPosts.length > 0 && (
            <div>
              <p className='postDetail_posts'>同じホテルに泊まった人</p>
              <PhotoBlock posts={sameHotelPosts} />
            </div>
          )}
        </BackGroundCard>
      </div>
    </StyledPostDetail>
  )
}

const StyledPostDetail = styled.div`
  color: ${({ theme }) => theme.primaryTextColor};
  height: 100vh;
  .background_photo {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .postDetail {
    position: absolute;
    top: 305px;
    z-index: 1;
    width: 100%;
    display: flex;
  }

  .postDetail_container {
    padding: 20px;
    margin-bottom: 20vh;
    border-radius: 15px 15px 20px 20px;
  }

  .postDetail_favorite {
    position: absolute;
    top: -26px;
    right: 20px;
    font-size: 3rem;
  }

  .edit_button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .postDetail_posts {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: -10px;
  }
`
