import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useFetchMyPosts } from 'components/functions/firebase/hooks/useFetchMyPosts'
import { useUser } from 'components/functions/recoil/hooks'
import { ProfileContainer } from 'components/page/profile/ProfileContainer'
import { PostsSwitchButton } from 'components/page/profile/PostsSwitchingButton'
import { PostsContainer } from 'components/page/profile/PostsContainer'
import { FavPosts } from 'components/page/profile/FavPosts'
import { fetchUser } from 'components/functions/firebase/hooks'
import styled from 'styled-components'

export const User = () => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const { fetchMyPosts, posts } = useFetchMyPosts(userId)
  const loginUser = useUser()

  // PostsのButtonのdisabledを切り替え状態管理 カスタムフックにする
  const [disabled, setDisabled] = useState(false)
  const [user, setUser] = useState({})
  const switchDisabled = useCallback(() => setDisabled(!disabled), [disabled])

  // 画像をクリックすると投稿詳細ページに遷移させる カスタムフックにする
  const onClickImg = (postId) => {
    navigate(`/${postId}`)
  }

  // 非同期処理まとめたやつ
  const request = async () => {
    await fetchMyPosts()
    setUser(await fetchUser(userId))
  }

  useEffect(async () => {
    // FIXME: useFetchMyPostsの中でuseEffectする
    try {
      await request()
    } catch {
      navigate('/404')
    }
  }, [])

  useEffect(() => {
    if (user.disabled) navigate('/404')
  }, [user])

  return (
    <StyledUser>
      <ProfileContainer user={user} isShow={user.userId === loginUser.userId} />
      <PostsSwitchButton disabled={disabled} switchDisabled={switchDisabled} />
      {disabled ? (
        <FavPosts favorites={user.favorite} onClickImg={onClickImg} />
      ) : (
        <PostsContainer posts={posts} onClickImg={onClickImg} />
      )}
    </StyledUser>
  )
}

const StyledUser = styled.div``
