import { Search } from 'components/page/search'
import { Footer } from 'layout/Footer'
import styled from 'styled-components'
import { MainHeader } from 'layout/Header/MainHeader'
import { SearchResetButton } from 'components/page/search/SearchResetButton'

const SearchPage = () => {
  return (
    <StyledSearch className='footer_margin'>
      <MainHeader className='searchHeader'>
        <SearchResetButton />
      </MainHeader>
      <Search />
      <Footer />
    </StyledSearch>
  )
}

export default SearchPage

const StyledSearch = styled.div`
  .searchHeader {
    background: ${({ theme }) => theme.background};
    z-index: 0;
  }
`
