import { useEffect, useState } from 'react'
import { getAuth, updateEmail } from 'firebase/auth'
import { db } from 'components/functions/firebase/config'
import { doc, updateDoc } from 'firebase/firestore'
import { changeProfileImg } from 'components/functions/firebase/hooks'
import { useUploadFiles } from 'components/functions/recoil/hooks'
import { checkMail } from 'components/functions/hooks/checkMail'
import { InputForm, Neumorphism, Button } from 'components/commons'
import { ImagePreview } from 'components/model/imagePostForm/ImagePreview'
import { ImgDropAndCrop } from 'components/page/profile/ImgDropAndCrop'
import styled from 'styled-components'

export const SettingProfile = ({
  user,
  authProvider,
  onClickPasswordResetButton,
}) => {
  const { userIcon, userId, userName, profile } = user
  const userRef = doc(db, 'users', userId)
  const auth = getAuth()
  const email = auth.currentUser.email
  const { uploadFiles, setUploadFiles } = useUploadFiles()
  const storagePath = `users/${userId}/`

  const [userData, setUserData] = useState({
    userIcon: userIcon,
    name: userName,
    profile: profile,
    email: email,
  })

  useEffect(() => {
    setUploadFiles([])
  }, [])

  // Formで入力された値を確認
  const formCheck = (userData) => {
    if (userData.name === '' || userData.email === '') {
      alert('未入力の箇所があります')
      return
    } else if (40 < userData.profile.length) {
      alert('自己紹介は40文字以内で入力してください')
      return
    } else if (!checkMail(userData.email)) {
      alert('メールアドレスが不正です')
      return
    } else {
      updateUserData()
    }
  }
  // profileの項目を更新
  const updateUserData = async () => {
    try {
      const uploadedImages = await changeProfileImg(
        userIcon,
        storagePath,
        uploadFiles
      )
      await updateDoc(userRef, {
        userIcon: uploadFiles.length ? uploadedImages[0] : userData.userIcon,
        profile: userData.profile,
        userName: userData.name,
      }),
        await updateEmail(auth.currentUser, userData.email)
      alert('プロフィール保存が完了しました')
    } catch (e) {
      alert('プロフィール保存に失敗しました')
      throw Error(e)
    } finally {
      window.location.reload()
    }
  }

  // 保存ボタンクリック時の処理
  const handleSaveProfile = () => {
    formCheck(userData)
  }

  return (
    <StyledSettingProfile>
      <Neumorphism>
        <p>ユーザーネーム</p>
        <InputForm
          type='text'
          defaultValue={userData.name}
          onChange={(event) =>
            setUserData({ ...userData, name: event.target.value })
          }
        />
        <p>自己紹介</p>
        <InputForm
          type='text'
          defaultValue={userData.profile}
          onChange={(event) =>
            setUserData({ ...userData, profile: event.target.value })
          }
        />
        <p>メールアドレス</p>
        <InputForm
          type='email'
          defaultValue={userData.email}
          // onChange={(event) =>
          //   setUserData({ ...userData, email: event.target.value })
          // }
          readonly={'readonly'}
        />
        <p>プロフィール画像変更</p>
        {uploadFiles.length ? <ImagePreview /> : <ImgDropAndCrop />}
        {authProvider === 'password' && (
          <Button onClick={onClickPasswordResetButton}>
            ログインパスワードを再設定する
          </Button>
        )}
        <Button onClick={handleSaveProfile}>保存</Button>
      </Neumorphism>
    </StyledSettingProfile>
  )
}

const StyledSettingProfile = styled.div`
  margin-top: 10px;
  p {
    margin-top: 10px;
  }
  InputForm {
    margin: 5px 0 10px;
  }
  Button {
    margin-top: 10px;
  }
`
