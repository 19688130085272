import { Button } from 'components/commons/Button'
import { useState } from 'react'
import styled from 'styled-components'
import { MoreVert } from '@mui/icons-material'

export const ProfileButton = ({ className, children }) => {
  const [moreActive, setMoreActive] = useState(false)

  return (
    <StyledProfileButton className={`${className}`}>
      <Button className='moreBtn' onClick={() => setMoreActive(!moreActive)}>
        <MoreVert sx={{ fontSize: 22 }} />
      </Button>
      <div className={`moreArea  drop_shadow ${moreActive ? '' : 'passive'}`}>
        {children}
      </div>
      <div
        className={moreActive ? 'closeArea' : ''}
        onClick={() => setMoreActive(!moreActive)}
      ></div>
    </StyledProfileButton>
  )
}

const StyledProfileButton = styled.div`
  .moreBtn {
    width: 40px;
    height: 40px;
    color: ${({ theme }) => theme.header.header_black};
  }

  .moreArea {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 57%;
    gap: 16px;
    position: absolute;
    top: 19px;
    right: 15px;
    z-index: 3;
    padding: 15px 18px;
    border-radius: 6px;
    background: ${({ theme }) => theme.background};
    &_text {
      font-size: 12px;
      color: #353535;
      cursor: pointer;
    }
  }

  .passive {
    display: none;
  }

  .closeArea {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
`
