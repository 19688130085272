import { ColorButton, InputForm } from 'components/commons'
import { useState } from 'react'
import styled from 'styled-components'

export const LoginForm = ({ login }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <StyledLoginForm>
      <form onSubmit={(e) => login(e, email, password)}>
        <div className='input_forms'>
          <InputForm
            className='input_form'
            label='メールアドレス'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputForm
            label='パスワード'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='login_button'>
          <ColorButton>ログイン</ColorButton>
        </div>
      </form>
    </StyledLoginForm>
  )
}

const StyledLoginForm = styled.div`
  animation-name: fade;
  animation-duration: 0.3s;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .input_forms {
    display: flex;
    flex-flow: column;
    row-gap: 20px;
    text-align: left;
  }
  .login_button {
    width: 40%;
    margin: 26px auto 0;
  }
`
