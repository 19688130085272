import { Button, InputForm } from 'components/commons'
import {
  changeUserData,
  getCurrentAuthUser,
} from 'components/functions/firebase/hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const UserNameForm = () => {
  const navigate = useNavigate()
  const [userName, setUserName] = useState('')
  const userId = getCurrentAuthUser().uid
  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      await changeUserData(userId, 'userName', userName)
      navigate('/user/profile')
    } catch {
      alert('エラーが発生しました')
    }
  }

  return (
    <StyledUserNameForm>
      <form onSubmit={(e) => onSubmit(e)}>
        <InputForm
          label='ユーザー名'
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        ></InputForm>
        <Button disabled={!userName} className='submit_button'>
          決定
        </Button>
      </form>
    </StyledUserNameForm>
  )
}

const StyledUserNameForm = styled.div`
  .submit_button {
    margin-top: 20px;
  }
`
