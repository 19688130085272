import { Navigate } from 'react-router-dom'
import { getCurrentAuthUser } from 'components/functions/firebase/hooks'

export const PrivateRoute = ({ Component }) => {
  const currentAuthUser = getCurrentAuthUser()

  if (currentAuthUser) {
    return <Component />
  } else {
    return <Navigate to='/login' replace />
  }
}
