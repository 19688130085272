import { createTheme } from '@mui/material/styles'
import { purple, deepPurple } from '@mui/material/colors'
import { css } from 'styled-components'

export const theme = createTheme({
  /* Styled-components theme */
  main: deepPurple[300],
  sub: purple[50],
  background: '#F4F4F4',
  textColor: ' #707070',
  primaryTextColor: '#353535',
  secondaryTextColor: '#999999',
  textColorBlack: '#353535',

  colors: {
    black: '#323232', // 黒
    blue: '#7ACAFF', // ブルー
    gray: '#727272', // グレー
    gray_grass: '#72727233', // グレー透明度20%
    white: '#fff', // 白
  },

  media: {
    sp: (...args) => css`
      @media (max-width: 599px) {
        ${css(...args)}
      }
    `,
    tab: (...args) => css`
      @media (min-width: 600px) {
        ${css(...args)}
      }
    `,
    pc: (...args) => css`
      @media (min-width: 1025px) {
        ${css(...args)}
      }
    `,
  },

  header: {
    header_white: '#ffffff',
    header_gray_grass: '#72727233',
    header_blue: '#7ACAFF',
    header_black: '#353535',
  },

  footer: {
    footer_black: '#323232',
    footer_blue: '#7ACAFF',
  },

  button: {
    text_color: '#F4F4F4',
    blue: '#7ACAFF',
    blue_shadow: '#7ACAFF80',
    disabled: '#999999',
    disabled_shadow: '#99999980',
  },
  slider: {
    color: '#7ACAFF',
    color_shadow: '#7ACAFF40',
    background: '#F4F4F4',
    shadow: '#00000029',
    blue: '#7ACAFF',
    green: '#08FFA4',
    red: '#FF087A',
  },
  dropzone: {
    background: '#EEEEEE',
    icon: '#323232',
  },

  /* MUI theme */
  palette: {
    primary: {
      main: deepPurple[300],
    },
    secondary: {
      main: purple[50],
    },
    text: {
      primary: '#fff',
      secondary: '#333',
    },
  },
  typography: {
    fontSize: 12,
    button: {
      textTransform: 'none',
    },
  },
  props: {
    MuiTextField: {
      variant: 'filled',
    },
  },
})
