import styled from 'styled-components'
import { useState, useEffect } from 'react'

const SplashScreen = () => {
  const [splashEvent, setSplashEvent] = useState(true)

  /* 初回のみスプラッシュ画面を表示 */
  useEffect(() => {
    const splashEvent = sessionStorage.getItem('SplashScreen')
    if (splashEvent == null) {
      setSplashEvent(false)
    }
    sessionStorage.setItem('SplashScreen', 'true')
  }, [])

  return (
    <StyledSplashScreen>
      <div className={`center ${splashEvent ? 'passive' : 'active'}`}>
        <span className='icon'>Sharm</span>
      </div>
    </StyledSplashScreen>
  )
}

export default SplashScreen

const StyledSplashScreen = styled.div`
  .icon {
    font-size: 3rem;
    color: #fff;
    font-family: 'Big John Pro';
    user-select: none;
  }

  .active {
    animation: start 1.5s ease-in-out 1.5s forwards;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #727272;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  @keyframes start {
    99% {
      width: 100%;
    }
    100% {
      opacity: 0;
      display: none;
      width: 0;
    }
  }

  .passive {
    display: none;
  }
`
