import { useState, useEffect } from 'react'
import { useTimeLineSortValue } from 'components/functions/recoil/hooks'
import styled from 'styled-components'
import { Filter } from 'akar-icons'
import { Checkbox } from 'components/commons'
import { searchAreaName } from 'components/page/search/searchAreaName'

export const SortCard = () => {
  const { timeLineSortValue, setTimeLineSortValue } = useTimeLineSortValue()
  const [filter, setFilter] = useState(false)
  useEffect(() => {
    setFilter(false)
  }, [])

  return (
    <StyledSortCard>
      <div className='sort'>
        <div
          className={`drop_shadow ${filter ? 'sort-square' : 'sort-circle'}`}
        >
          <Filter
            onClick={() => setFilter(!filter)}
            size={24}
            strokeWidth={1.5}
            className={`sort-icon ${
              timeLineSortValue.mainAddress !== '選択なし'
                ? 'sort-activeColor'
                : 'sort-passiveColor'
            }`}
          />
          <div className={`${filter ? '' : 'sort-passive'}`}>
            <p className='sort-title'>場所</p>
            {Object.keys(searchAreaName).map((pref) => {
              if (pref === '選択なし') return
              return (
                <Checkbox
                  key={pref}
                  address={searchAreaName}
                  pref={pref}
                  className={'sort-radioButton'}
                  sortValue={timeLineSortValue}
                  setSortValue={setTimeLineSortValue}
                />
              )
            })}
          </div>
        </div>
        <div
          className={filter ? 'sort-closeArea' : ''}
          onClick={() => setFilter(!filter)}
        ></div>
      </div>
    </StyledSortCard>
  )
}

const StyledSortCard = styled.div`
  .sort {
    width: 100%;
    padding-bottom: 20px;
    overflow: hidden;
    position: absolute;
    top: 0;
    color: ${({ theme }) => theme.header.header_black};

    &-circle {
      margin: -20px -20px 0 auto;
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.background};
    }

    &-square {
      margin: 0 auto;
      width: 100%;
      height: 600px;
      border-radius: 0 0 30px 30px;
      position: relative;
      background-color: ${({ theme }) => theme.background};
      z-index: 1;
      padding-top: 32px;
      padding-left: 28px;
    }

    &-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    &-activeColor {
      color: ${({ theme }) => theme.header.header_blue};
    }
    &-passiveColor {
      color: ${({ theme }) => theme.textColor};
    }

    &-title {
      font-size: 1.8rem;
      margin-bottom: 18px;
      letter-spacing: 3.6px;
    }

    &-passive {
      display: none;
    }

    &-radioButton {
      font-size: 1.5rem;
    }

    &-closeArea {
      width: 100vw;
      height: 100vh;
      height: 100dvh;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
`
