import { PostDetail } from 'components/page/postDetail'
import { ProfileButton } from 'components/page/profile/ProfileButton'
import { Footer } from 'layout/Footer'
import { MainHeader } from 'layout/Header/MainHeader'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const PostDetailPage = () => {
  const [data, setData] = useState({ post: {}, hotel: {} })
  const navigate = useNavigate()

  return (
    <StyledPostDetail className='footer_margin'>
      <MainHeader>
        <ProfileButton>
          <p
            className={'moreArea_text report'}
            onClick={() =>
              navigate('/contact', { state: { postId: data.post.postId } })
            }
          >
            この投稿を通報する
          </p>
        </ProfileButton>
      </MainHeader>
      <PostDetail data={data} setData={setData} />
      <Footer />
    </StyledPostDetail>
  )
}

export default PostDetailPage

const StyledPostDetail = styled.div`
  position: relative;
  .report {
    color: ${({ theme }) => theme.main};
  }
`
