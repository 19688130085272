import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { userState } from 'components/functions/recoil/atom'
import {
  getUserData,
  getCurrentAuthUser,
} from 'components/functions/firebase/hooks'

export const useUser = () => {
  const [user, setUser] = useRecoilState(userState)
  const currentAuthUser = getCurrentAuthUser()

  useEffect(async () => {
    if (currentAuthUser !== null) {
      const uid = currentAuthUser.uid
      const userData = await getUserData(uid)

      setUser(userData)
    }
  }, [])
  return { user, setUser }
}
