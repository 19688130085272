import { Location } from 'akar-icons'
import { checkMunicipalities } from 'components/functions/hooks/checkMunicipalities'
import styled from 'styled-components'

export const OfficialHotelInfo = ({ hotelData }) => {
  const {
    url,
    rakutenUrl,
    hotelName,
    prefecture,
    city,
    hotelMinCharge,
    access,
    checkinTime,
    checkoutTime,
  } = hotelData
  return (
    <StyledOfficialHotelInfo>
      <p className='hotel_info_title'>公式詳細情報</p>
      <div className='hotel_info_container'>
        <p className='hotel_name'>{hotelName}</p>
        <div className='hotel_data_wrapper'>
          <div className='hotel_location'>
            <Location size={14} strokeWidth={1.5} />
            <div className='hotel_locationName'>
              {prefecture}
              {checkMunicipalities(city)}
            </div>
          </div>
          <div className='hotel_detail'>
            <p>
              <span className='hotel_detail_item'>宿泊料金</span>
              <span>:</span>
              <span className='hotel_detail_content'>
                {hotelMinCharge ? hotelMinCharge.toLocaleString() : ''}円~/人
              </span>
            </p>
            <p>
              <span className='hotel_detail_item'>住所</span>
              <span>:</span>
              <span className='hotel_detail_content'>
                {prefecture} {city}
              </span>
            </p>
            <p>
              <span className='hotel_detail_item'>アクセス</span>
              <span>:</span>
              <span className='hotel_detail_content'>
                {access ? access : ''}
              </span>
            </p>
            <p>
              <span className='hotel_detail_item'>宿泊時間</span>
              <span>:</span>
              <span className='hotel_detail_content'>
                {checkinTime} （IN） 〜 {checkoutTime} （OUT）など
              </span>
            </p>
          </div>
        </div>
        <div className='hotel_links'>
          {url && (
            <a
              href={url}
              target='_blank'
              rel='noopener noreferrer'
              className='link official_hp'
            >
              公式HP
            </a>
          )}
          <a
            href={rakutenUrl}
            target='_blank'
            rel='noopener noreferrer'
            className='link'
          >
            <img
              src={
                'https://hbb.afl.rakuten.co.jp/hsb/29299922.a907c3de.29295fb8.7378ef79/?me_id=2100001&me_adv_id=646466&t=pict'
              }
              alt='楽天トラベルバナー'
              className='rakuten_banner'
            />
          </a>
        </div>
      </div>
    </StyledOfficialHotelInfo>
  )
}

const StyledOfficialHotelInfo = styled.div`
  .hotel_info_title {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 7px;
  }

  .hotel_name {
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: underline;
  }

  .hotel_info_container {
    padding: 20px;
    background-color: #eee;
    border-radius: 10px;
  }

  .hotel_data_wrapper {
    margin: 10px 0;
  }

  .hotel_location {
    display: flex;
  }

  .hotel_locationName {
    margin-left: 5px;
  }

  .hotel_detail {
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .hotel_detail_item {
    display: inline-block;
    width: 50px;
    color: #7a7a7a;
  }

  .hotel_detail_content {
    margin-left: 5px;
  }

  .hotel_links {
    width: 100%;
    display: flex;
    column-gap: 10px;
    text-align: center;
  }

  .official_hp {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 33px;
    cursor: pointer;
  }

  .link {
    width: 100%;
    border-radius: 5px;
    height: 33px;
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.white};
    align-items: center;
  }

  .rakuten_banner {
    height: 33px;
    cursor: pointer;
  }
`
