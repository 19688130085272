import { useState, useEffect, useCallback } from 'react'
import { db } from 'components/functions/firebase/config'
import { getDocs, query, collection, where } from 'firebase/firestore'

const MAX_QUERY_IN = 10

// 配列を分割する関数
const splitArray = (array, resultSize) => {
  const length = Math.ceil(array.length / resultSize)
  return new Array(length)
    .fill()
    .map((_, i) => array.slice(i * resultSize, (i + 1) * resultSize))
}

// 並び替えのマッピング配列を作成する関数
const createOrderMap = (array) => {
  const maxIndex = array.length - 1
  const orderMap = {}
  array.forEach((element, index) => (orderMap[element] = maxIndex - index))
  return orderMap
}

export const useFetchFavoritePosts = (favorites) => {
  const [favoritePosts, setFavoritePosts] = useState([])

  const fetchData = useCallback(
    async (favorites) => {
      const favoritesPostArray = []

      // 並び替えのマッピング配列を作成する
      const orderMap = createOrderMap(favorites)

      if (favorites && favorites.length > 0) {
        // queryのinが最大10件まで対応のため、配列を分割し検索する
        const queryFavorites = splitArray(favorites, MAX_QUERY_IN)

        await Promise.all(
          queryFavorites.map(async (queryFavorite) => {
            const filter = query(
              collection(db, 'posts'),
              where('disabled', '==', false),
              where('postId', 'in', queryFavorite)
            )
            const querySnapshot = await getDocs(filter)

            querySnapshot.docs.forEach((doc) => {
              const postId = doc.data().postId

              // 並び替えマッピング配列を使って、お気に入り登録順に並び替えを行う
              favoritesPostArray[orderMap[postId]] = {
                postId,
                postImg: doc.data().postImg[0],
              }
            })
          })
        )
      }
      return favoritesPostArray
    },
    [favorites]
  )

  useEffect(() => {
    let notUnmounted = true

    const setData = async (notUnmounted) => {
      let favoritesPostArray = []
      if (favorites && favorites.length > 0) {
        favoritesPostArray = await fetchData(favorites)
      }
      if (notUnmounted) {
        setFavoritePosts(favoritesPostArray)
      }
    }

    setData(notUnmounted)

    return () => (notUnmounted = false)
  }, [])

  return { favoritePosts }
}
